define("aboyeur/components/etw/module-style-detail", ["exports", "ember-cli-tailwind/utils/classes-for-module-style"], function (_exports, _classesForModuleStyle) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    etwTailwindStyleguide: Ember.inject.service(),
    moduleStyle: Ember.computed.reads('etwTailwindStyleguide.selectedModuleStyle'),
    activeResponsiveClass: 'all',
    activeState: 'none',
    detailStyles: Ember.computed('moduleStyle', 'activeResponsiveClass', 'activeState', function () {
      let moduleStyle = this.moduleStyle;
      let activeResponsiveClass = this.activeResponsiveClass;
      let responsivePrefix = activeResponsiveClass === 'all' ? '' : `${activeResponsiveClass}:`;
      let activeState = this.activeState;
      let statePrefix = activeState === 'none' ? '' : `${activeState}:`;
      return (0, _classesForModuleStyle.default)(moduleStyle).map(cssClass => {
        return `${responsivePrefix}${statePrefix}${cssClass}`;
      });
    }),
    actions: {
      highlightStyle(style) {
        this.set('highlightedStyle', style);
        Ember.run.later(() => {
          this.set('highlightedStyle', null);
        }, 1500);
      }

    }
  });

  _exports.default = _default;
});