define("aboyeur/templates/components/menu-import", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "suegC559",
    "block": "{\"symbols\":[\"@partner\"],\"statements\":[[10,\"div\"],[14,0,\"border-black border px-4 pt-3 pb-3 mb-2 mt-8 mx-auto flex rounded my-2\"],[12],[2,\"\\n\"],[6,[37,0],[[30,[36,1],[[32,1,[\"menuImportStatus\",\"success\"]]],null]],null,[[\"default\"],[{\"statements\":[[6,[37,0],[[32,1,[\"menuImportStatus\",\"success\"]]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"      \"],[10,\"div\"],[14,0,\"w-10/12 h-auto text-black\"],[12],[2,\"Menu Import Successful\"],[13],[2,\"\\n      \"],[10,\"div\"],[14,0,\"w-4/12\"],[12],[2,\"\\n        \"],[8,\"status-time\",[],[[\"@statusTime\"],[[32,1,[\"menuImportStatus\",\"timestamp\"]]]],[[\"default\"],[{\"statements\":[],\"parameters\":[]}]]],[2,\"\\n      \"],[13],[2,\"\\n      \"],[10,\"div\"],[14,0,\"w-1/12 h-auto\"],[12],[10,\"i\"],[14,0,\"float-right fa fa-check-circle text-green\"],[14,\"aria-hidden\",\"true\"],[12],[13],[13],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"      \"],[10,\"div\"],[14,0,\"w-7/12 h-auto text-black\"],[12],[2,\"Menu Import Unsuccessful \"],[13],[2,\"\\n      \"],[10,\"div\"],[14,0,\"w-4/12\"],[12],[2,\"\\n        \"],[8,\"status-time\",[[24,0,\"w-4/12\"]],[[\"@statusTime\"],[[32,1,[\"menuImportStatus\",\"timestamp\"]]]],[[\"default\"],[{\"statements\":[],\"parameters\":[]}]]],[2,\"\\n      \"],[13],[2,\"\\n      \"],[10,\"div\"],[14,0,\"w-1/12 h-auto\"],[12],[10,\"i\"],[14,0,\"float-right fa fa-exclamation-triangle text-orange\"],[14,\"aria-hidden\",\"true\"],[12],[13],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"parameters\":[]}]]],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"if\",\"is-defined\"]}",
    "meta": {
      "moduleName": "aboyeur/templates/components/menu-import.hbs"
    }
  });

  _exports.default = _default;
});