define("aboyeur/routes/details", ["exports", "aboyeur/routes/protected"], function (_exports, _protected) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const Detail = Ember.Object.extend({
    id: '',
    type: '',
    timestamp: '',
    success: ''
  });

  class DetailsRoute extends _protected.default {
    model() {
      let detail_status1 = Ember.Object.create({
        "id": 155,
        "type": "Menu Import",
        "timestamp": "last tuesday",
        "success": true
      });
      let detail_status2 = Ember.Object.create({
        "id": 169,
        "type": "Menu Publish",
        "timestamp": "last monday",
        "success": false
      });
      return Ember.A([detail_status1, detail_status2]);
    }

  }

  _exports.default = DetailsRoute;
});