define("aboyeur/components/layout/icon-button", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <button ...attributes>
  {{fa-icon @icon}}  
  </button>
  */
  {
    id: "yaC8GY3K",
    block: "{\"symbols\":[\"&attrs\",\"@icon\"],\"statements\":[[11,\"button\"],[17,1],[12],[2,\"\\n\"],[10,\"i\"],[15,0,[31,[\"fa fa-\",[32,2]]]],[14,\"aria-hidden\",\"true\"],[12],[13],[2,\"  \\n\"],[13]],\"hasEval\":false,\"upvars\":[]}",
    meta: {
      moduleName: "aboyeur/components/layout/icon-button.hbs"
    }
  });

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());

  _exports.default = _default;
});