define("aboyeur/templates/components/status-time", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "/0jdtWn/",
    "block": "{\"symbols\":[\"@statusTime\"],\"statements\":[[2,\" \"],[10,\"div\"],[14,0,\"h-auto italic text-black\"],[12],[2,\" \\n  \"],[1,[30,[36,0],[[32,1]],[[\"hour\",\"minute\"],[\"numeric\",\"numeric\"]]]],[2,\" on \"],[1,[30,[36,1],[[32,1]],[[\"weekday\",\"day\",\"month\",\"year\"],[\"long\",\"numeric\",\"numeric\",\"numeric\"]]]],[2,\" \\n\"],[13]],\"hasEval\":false,\"upvars\":[\"format-time\",\"format-date\"]}",
    "meta": {
      "moduleName": "aboyeur/templates/components/status-time.hbs"
    }
  });

  _exports.default = _default;
});