define("aboyeur/templates/components/search-bar", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "V6b/OswS",
    "block": "{\"symbols\":[\"partner\",\"@searchChowly\",\"@searchAction\"],\"statements\":[[10,\"div\"],[14,0,\"mb-4 flex flex-col\"],[12],[2,\"\\n  \"],[8,\"label\",[],[[],[]],[[\"default\"],[{\"statements\":[[2,\"Search\"]],\"parameters\":[]}]]],[2,\"\\n  \"],[8,\"power-select\",[],[[\"@search\",\"@searchField\",\"@searchEnabled\",\"@placeholder\",\"@onChange\",\"@searchMessage\",\"@value\"],[[32,2],\"name\",true,\"Search Location\",[32,3],\"Type at least 3 letters to search\",\"id\"]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[1,[32,1,[\"name\"]]],[2,\"\\n  \"]],\"parameters\":[1]}]]],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[]}",
    "meta": {
      "moduleName": "aboyeur/templates/components/search-bar.hbs"
    }
  });

  _exports.default = _default;
});