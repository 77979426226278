define("aboyeur/components/layout/box", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <div class="bg-white border-black border px-8 pt-6 pb-8 mb-4 flex rounded flex-col my-2">
    {{yield}}  
  </div>
  
  */
  {
    id: "4T7GfIDa",
    block: "{\"symbols\":[\"&default\"],\"statements\":[[10,\"div\"],[14,0,\"bg-white border-black border px-8 pt-6 pb-8 mb-4 flex rounded flex-col my-2\"],[12],[2,\"\\n  \"],[18,1,null],[2,\"  \\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[]}",
    meta: {
      moduleName: "aboyeur/components/layout/box.hbs"
    }
  });

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());

  _exports.default = _default;
});