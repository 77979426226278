define("aboyeur/templates/components/status-icon", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "1hJOQsIJ",
    "block": "{\"symbols\":[],\"statements\":[[6,[37,0],[[32,0,[\"success\"]]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"  \"],[10,\"i\"],[14,0,\"float-right fa fa-check-circle fa-2x text-green\"],[14,\"aria-hidden\",\"true\"],[12],[13],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"  \"],[10,\"i\"],[14,0,\"float-right fa fa-exclamation-triangle fa-2x text-orange\"],[14,\"aria-hidden\",\"true\"],[12],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"if\"]}",
    "meta": {
      "moduleName": "aboyeur/templates/components/status-icon.hbs"
    }
  });

  _exports.default = _default;
});