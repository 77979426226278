define("aboyeur/helpers/nav-class", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Helper.helper(function navClass(route) {
    const uri = route[0];
    const label = route[1];

    if (uri.toLowerCase() === label.toLowerCase()) {
      return 'text-white bg-black';
    }

    return 'hover:bg-black hover:text-white text-black active:bg-grey-dark';
  });

  _exports.default = _default;
});