define("aboyeur/adapters/partner", ["exports", "@babel/runtime/helpers/esm/defineProperty", "aboyeur/adapters/aboyeur"], function (_exports, _defineProperty2, _aboyeur) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class PartnerAdapter extends _aboyeur.default {
    constructor(...args) {
      super(...args);
      (0, _defineProperty2.default)(this, "namespace", 'api/v3');
    }

  }

  _exports.default = PartnerAdapter;
});