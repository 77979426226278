define("aboyeur/routes/partners/partner", ["exports", "aboyeur/routes/protected"], function (_exports, _protected) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class PartnerRoute extends _protected.default {
    model(params) {
      return this.store.findRecord('partner', params.partner_id, {
        include: 'menu_import_status,order_platforms,order_platforms.online_status,order_platforms.menu_publish_status'
      });
    }

  }

  _exports.default = PartnerRoute;
});