define("aboyeur/components/menu-publish", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    successes: Ember.computed('orderPlatforms', function () {
      return this.orderPlatforms.getEach('menuPublishStatus.success').compact();
    }),
    numSuccess: Ember.computed('successes', function () {
      return this.successes.filter(x => x).length;
    }),
    numFail: Ember.computed('numSuccess', 'successes.length', function () {
      return this.successes.length - this.numSuccess;
    })
  });

  _exports.default = _default;
});