define("aboyeur/routes/printers/printer", ["exports", "aboyeur/routes/protected"], function (_exports, _protected) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class PrinterRoute extends _protected.default {
    model(params) {
      return this.store.findRecord('printer', params.printer_id, {
        include: 'partner,status'
      });
    }

  }

  _exports.default = PrinterRoute;
});