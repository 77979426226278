define("aboyeur/controllers/partners/index", ["exports", "@babel/runtime/helpers/esm/defineProperty", "@babel/runtime/helpers/esm/applyDecoratedDescriptor", "jw-paginate"], function (_exports, _defineProperty2, _applyDecoratedDescriptor2, _jwPaginate) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _class, _temp;

  let IndexController = (_dec = Ember.computed('model.meta.pagination.{last,self}'), _dec2 = Ember._action, _dec3 = Ember._action, _dec4 = Ember._action, _dec5 = Ember._action, _dec6 = Ember._action, (_class = (_temp = class IndexController extends Ember.Controller {
    constructor(...args) {
      super(...args);
      (0, _defineProperty2.default)(this, "menuImportOptions", [{
        'label': 'Show All',
        value: {
          menu_import: undefined
        }
      }, {
        'label': 'Menu Import: Successful',
        'value': {
          menu_import: 'successful'
        }
      }, {
        'label': 'Menu Import: Failed',
        'value': {
          menu_import: 'failed'
        }
      }]);
      (0, _defineProperty2.default)(this, "importOption", 'Show All');
      (0, _defineProperty2.default)(this, "importLabels", this.menuImportOptions.map(a => a.label));
      (0, _defineProperty2.default)(this, "menuPublishOptions", [{
        'label': 'Show All',
        'value': {
          menu_publish: undefined
        }
      }, {
        'label': 'Menu Publish: Failed',
        'value': {
          menu_publish: 'failed'
        }
      }]);
      (0, _defineProperty2.default)(this, "publishOption", 'Show All');
      (0, _defineProperty2.default)(this, "publishLabels", this.menuPublishOptions.map(a => a.label));
      (0, _defineProperty2.default)(this, "onlineOptions", [{
        'label': 'Show All',
        'value': {
          toos_online_status: undefined
        }
      }, {
        'label': 'Show Offline Locations',
        'value': {
          toos_online_status: 'offline'
        }
      }]);
      (0, _defineProperty2.default)(this, "onlineOption", 'Show All');
      (0, _defineProperty2.default)(this, "onlineLabels", this.onlineOptions.map(a => a.label));
      (0, _defineProperty2.default)(this, "sortOptions", [{
        'label': 'Sort A-Z',
        'value': {
          sort_by: 'ASC'
        }
      }, {
        'label': 'Sort Z-A',
        'value': {
          sort_by: 'DESC'
        }
      }]);
      (0, _defineProperty2.default)(this, "sortOption", 'Sort A-Z');
      (0, _defineProperty2.default)(this, "sortLabels", this.sortOptions.map(a => a.label));
    }

    selectImport(label) {
      this.set('queryParam', this.menuImportOptions.find(o => o.label === label)['value']);
      this.transitionToRoute({
        queryParams: this.queryParam
      });
      return this.set('importOption', label);
    }

    selectPublish(label) {
      this.set('queryParam', this.menuPublishOptions.find(o => o.label === label)['value']);
      this.transitionToRoute({
        queryParams: this.queryParam
      });
      return this.set('publishOption', label);
    }

    selectFilter(label) {
      this.set('queryParam', this.filterOptions.find(o => o.label === label)['value']);
      this.transitionToRoute({
        queryParams: this.queryParam
      });
      return this.set('labelOption', label);
    }

    selectSort(option) {
      this.set('queryParam', this.sortOptions.find(o => o.label === option)['value']);
      this.transitionToRoute({
        queryParams: this.queryParam
      });
      return this.set('sortOption', option);
    }

    selectOnline(option) {
      this.set('queryParam', this.onlineOptions.find(o => o.label === option)['value']);
      this.transitionToRoute({
        queryParams: this.queryParam
      });
      return this.set('onlineOption', option);
    }

    get count() {
      const total = this.model.meta.total;
      if (!total) return [];
      const currentPage = this.model.meta.pagination.self;
      const pagination = (0, _jwPaginate.default)(total, currentPage, 25);
      return pagination.pages;
    }

  }, _temp), ((0, _applyDecoratedDescriptor2.default)(_class.prototype, "selectImport", [_dec, _dec2], Object.getOwnPropertyDescriptor(_class.prototype, "selectImport"), _class.prototype), (0, _applyDecoratedDescriptor2.default)(_class.prototype, "selectPublish", [_dec3], Object.getOwnPropertyDescriptor(_class.prototype, "selectPublish"), _class.prototype), (0, _applyDecoratedDescriptor2.default)(_class.prototype, "selectFilter", [_dec4], Object.getOwnPropertyDescriptor(_class.prototype, "selectFilter"), _class.prototype), (0, _applyDecoratedDescriptor2.default)(_class.prototype, "selectSort", [_dec5], Object.getOwnPropertyDescriptor(_class.prototype, "selectSort"), _class.prototype), (0, _applyDecoratedDescriptor2.default)(_class.prototype, "selectOnline", [_dec6], Object.getOwnPropertyDescriptor(_class.prototype, "selectOnline"), _class.prototype)), _class));
  _exports.default = IndexController;
});