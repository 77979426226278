define("aboyeur/routes/index", ["exports", "aboyeur/routes/protected"], function (_exports, _protected) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class IndexRoute extends _protected.default {
    beforeModel() {
      this.transitionTo('partners');
    }

  }

  _exports.default = IndexRoute;
});