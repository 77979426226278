define("aboyeur/templates/login", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "uMEFjPJo",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[14,0,\"flex h-screen\"],[12],[2,\"\\n  \"],[10,\"div\"],[14,0,\"m-auto\"],[12],[2,\"\\n    \"],[10,\"h1\"],[14,0,\"text-center mb-8\"],[12],[2,\"aboyeur.\"],[13],[2,\"\\n    \"],[8,\"layout/button\",[[16,\"onclick\",[30,[36,1],[[32,0],[35,0]],null]]],[[\"@type\"],[\"plain\"]],[[\"default\"],[{\"statements\":[[2,\"Sign In with Chowly\"]],\"parameters\":[]}]]],[2,\"\\n  \"],[13],[2,\"\\n\"],[13],[2,\"\\n\\n\\n\"],[1,[30,[36,3],[[30,[36,2],null,null]],null]]],\"hasEval\":false,\"upvars\":[\"connectChowly\",\"action\",\"-outlet\",\"component\"]}",
    "meta": {
      "moduleName": "aboyeur/templates/login.hbs"
    }
  });

  _exports.default = _default;
});