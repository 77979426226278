define("aboyeur/components/layout/collapser-button", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <Layout::IconButton @icon={{if @isOpen "minus" "plus"}}></Layout::IconButton>
  */
  {
    id: "GwmEMBYQ",
    block: "{\"symbols\":[\"@isOpen\"],\"statements\":[[8,\"layout/icon-button\",[],[[\"@icon\"],[[30,[36,0],[[32,1],\"minus\",\"plus\"],null]]],[[\"default\"],[{\"statements\":[],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"if\"]}",
    meta: {
      moduleName: "aboyeur/components/layout/collapser-button.hbs"
    }
  });

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());

  _exports.default = _default;
});