define("aboyeur/instance-initializers/ember-cli-tailwind", ["exports", "aboyeur/router"], function (_exports, _router) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.initialize = initialize;
  _exports.default = void 0;
  const TailwindApplicationRoute = Ember.Route.extend({
    router: Ember.inject.service('-routing'),

    renderTemplate() {
      this.render('applicationTailwind', {
        controller: this.controllerFor('applicationTailwind')
      });
    }

  });

  function initialize(appInstance) {
    let fastboot = appInstance.lookup('service:fastboot');
    let fastbootIsInstalled = fastboot;
    let fastbootIsNotInstalled = !fastboot;
    let notUsingFastboot = fastbootIsNotInstalled || fastbootIsInstalled && !fastboot.get('isFastBoot');

    let router = appInstance.lookup('service:router')._router;

    let initialURL = router.initialURL || (window && window.location ? window.location.href : ''); // fastboot guard :/

    if (notUsingFastboot && initialURL.match('/tailwind')) {
      appInstance.register('route:application', TailwindApplicationRoute);

      _router.default.map(function () {
        this.route('tailwind');
      });
    }
  }

  var _default = {
    initialize
  };
  _exports.default = _default;
});