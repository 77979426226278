define("aboyeur/adapters/aboyeur", ["exports", "ember-data", "ember-simple-auth/mixins/data-adapter-mixin", "aboyeur/config/environment"], function (_exports, _emberData, _dataAdapterMixin, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    JSONAPIAdapter
  } = _emberData.default;

  var _default = JSONAPIAdapter.extend(_dataAdapterMixin.default, {
    session: Ember.inject.service(),

    get authorizer() {
      return this.session.data.authenticated.authenticator.replace("authenticator", "authorizer");
    },

    host: _environment.default.api.host,

    ajaxOptions() {
      let hash = this._super(...arguments);

      if (this.authorizer) {
        const auth = this.session.data.authenticated;
        const myHeaders = {};
        myHeaders['Authorization'] = `${auth.token_type} ${auth.access_token}`;
        const headers = Object.assign({}, hash['headers'], myHeaders);
        hash['headers'] = headers;
      }

      return hash;
    }

  });

  _exports.default = _default;
});