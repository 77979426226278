define("aboyeur/services/chowly-api", ["exports", "@babel/runtime/helpers/esm/initializerDefineProperty", "@babel/runtime/helpers/esm/defineProperty", "@babel/runtime/helpers/esm/applyDecoratedDescriptor", "@babel/runtime/helpers/esm/initializerWarningHelper", "fetch", "aboyeur/config/environment"], function (_exports, _initializerDefineProperty2, _defineProperty2, _applyDecoratedDescriptor2, _initializerWarningHelper2, _fetch2, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _class, _descriptor, _temp;

  let ChowlyApiService = (_dec = Ember.inject.service, (_class = (_temp = class ChowlyApiService extends Ember.Service {
    constructor(...args) {
      super(...args);
      (0, _initializerDefineProperty2.default)(this, "session", _descriptor, this);
    }

    async massCreate(partners) {
      return await this.fetch('utilities/mass_create', {
        method: "POST",
        body: JSON.stringify({
          partners: partners
        })
      });
    }

    async plaunchMenu(source, destinations) {
      return await this.fetch('utilities/plaunch_menu', {
        method: "POST",
        body: JSON.stringify({
          source: source,
          destinations: destinations
        })
      });
    }

    async getSas(container, filename) {
      return await this.fetch('images', {
        method: "POST",
        body: JSON.stringify({
          container: container,
          file_name: filename
        })
      });
    }

    async cloneAndCreate(partners, platforms) {
      let resp = await this.massCreate(partners);
      resp = await resp.json();
      let platIds = platforms.map(el => el.id);
      let response = await this.fetch(`utilities/clone_partner_platform`, {
        method: "POST",
        body: JSON.stringify({
          partner_ids: resp.partner_ids,
          platform_ids: platIds
        })
      });
      return await response.json();
    }

    fetch(url, options = {}) {
      return new Promise((resolve, reject) => {
        const auth = this.session.data.authenticated;
        options = Object.assign({
          headers: {
            "Content-Type": "application/json",
            "Authorization": `${auth.token_type} ${auth.access_token}`
          }
        }, options);
        resolve((0, _fetch2.default)(`${_environment.default.api.host}/${_environment.default.api.namespace}/${url}`, options));
      });
    }

  }, _temp), (_descriptor = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "session", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class));
  _exports.default = ChowlyApiService;
});