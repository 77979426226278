define("aboyeur/components/details", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <div>
    {{#if @detail.success}}
      Successful
    {{else}}
      Failed
    {{/if}}
    {{@detail.type}} {{@detail.timestamp}}
  </div>
  
  */
  {
    id: "+zn+Qg2t",
    block: "{\"symbols\":[\"@detail\"],\"statements\":[[10,\"div\"],[12],[2,\"\\n\"],[6,[37,0],[[32,1,[\"success\"]]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"    Successful\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"    Failed\\n\"]],\"parameters\":[]}]]],[2,\"  \"],[1,[32,1,[\"type\"]]],[2,\" \"],[1,[32,1,[\"timestamp\"]]],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"if\"]}",
    meta: {
      moduleName: "aboyeur/components/details.hbs"
    }
  });

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());

  _exports.default = _default;
});