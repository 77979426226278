define("aboyeur/router", ["exports", "@babel/runtime/helpers/esm/defineProperty", "aboyeur/config/environment"], function (_exports, _defineProperty2, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class Router extends Ember.Router {
    constructor(...args) {
      super(...args);
      (0, _defineProperty2.default)(this, "location", _environment.default.locationType);
      (0, _defineProperty2.default)(this, "rootURL", _environment.default.rootURL);
    }

  }

  _exports.default = Router;
  Router.map(function () {
    this.route('login');
    this.route('partners', function () {
      this.route('index', {
        path: '/'
      });
      this.route('partner', {
        path: '/:partner_id'
      });
    });
    this.route('printers', function () {
      this.route('index', {
        path: '/'
      });
      this.route('printer', {
        path: '/:printer_id'
      });
    });
  });
});