define("aboyeur/controllers/partners", ["exports", "@babel/runtime/helpers/esm/applyDecoratedDescriptor", "jw-paginate"], function (_exports, _applyDecoratedDescriptor2, _jwPaginate) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _class;

  let PartnersController = (_dec = Ember.computed('model.meta.pagination.{last,self}'), _dec2 = Ember._action, _dec3 = Ember._action, (_class = class PartnersController extends Ember.Controller {
    searchChowly(partner_id) {
      if (partner_id.length < 3) {
        return;
      }

      return this.store.query('partner', {
        q: partner_id,
        include: 'menu_import_status,order_platforms,order_platforms.online_status,order_platforms.menu_publish_status'
      });
    }

    goToPartner(partner_id) {
      this.transitionToRoute('partners.partner', partner_id);
    }

    get count() {
      const total = this.model.meta.total;
      if (!total) return [];
      const currentPage = this.model.meta.pagination.self;
      const pagination = (0, _jwPaginate.default)(total, currentPage, 25);
      return pagination.pages;
    }

  }, ((0, _applyDecoratedDescriptor2.default)(_class.prototype, "searchChowly", [_dec, _dec2], Object.getOwnPropertyDescriptor(_class.prototype, "searchChowly"), _class.prototype), (0, _applyDecoratedDescriptor2.default)(_class.prototype, "goToPartner", [_dec3], Object.getOwnPropertyDescriptor(_class.prototype, "goToPartner"), _class.prototype)), _class));
  _exports.default = PartnersController;
});