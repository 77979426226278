define("aboyeur/torii-providers/chowly-oauth2", ["exports", "torii/providers/oauth2-code", "torii/configuration"], function (_exports, _oauth2Code, _configuration) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  // Struggling to convert this file, seems related to the way computed properties are set on Native classes.
  // Issue is mainly around configurable
  var _default = _oauth2Code.default.extend({
    name: 'chowly-oauth2',
    baseUrl: (0, _configuration.configurable)('baseUrl', 'https://admin.chowlyinc.com'),
    responseParams: ['code', 'state'],
    redirectUri: (0, _configuration.configurable)('redirectUri', function () {
      return this._super();
    }),

    fetch(data) {
      return data;
    }

  });

  _exports.default = _default;
});