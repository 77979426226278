define("aboyeur/components/layout/nav", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <nav class="flex justify-between border-b border-black p-6">
    <div class="flex-grow-0 mt-2 mr-10 block">
      <LinkTo @route="application"><span class="font-semibold text-xl text-black tracking-tight">aboyeur.</span></LinkTo>
    </div>
    <div class="block lg:hidden">
    </div>
    <div class="w-full mt-1 block flex-grow lg:flex lg:items-center lg:w-auto">
      <Layout::NavMenu />
    </div>
    <div class="float-right">
      <Layout::Button data-test-signout-link @type="plain" onclick={{action @signOut}}>Sign Out</Layout::Button>
    </div>
  </nav>
  {{yield}}
  */
  {
    id: "evbIJNYF",
    block: "{\"symbols\":[\"@signOut\",\"&default\"],\"statements\":[[10,\"nav\"],[14,0,\"flex justify-between border-b border-black p-6\"],[12],[2,\"\\n  \"],[10,\"div\"],[14,0,\"flex-grow-0 mt-2 mr-10 block\"],[12],[2,\"\\n    \"],[8,\"link-to\",[],[[\"@route\"],[\"application\"]],[[\"default\"],[{\"statements\":[[10,\"span\"],[14,0,\"font-semibold text-xl text-black tracking-tight\"],[12],[2,\"aboyeur.\"],[13]],\"parameters\":[]}]]],[2,\"\\n  \"],[13],[2,\"\\n  \"],[10,\"div\"],[14,0,\"block lg:hidden\"],[12],[2,\"\\n  \"],[13],[2,\"\\n  \"],[10,\"div\"],[14,0,\"w-full mt-1 block flex-grow lg:flex lg:items-center lg:w-auto\"],[12],[2,\"\\n    \"],[8,\"layout/nav-menu\",[],[[],[]],null],[2,\"\\n  \"],[13],[2,\"\\n  \"],[10,\"div\"],[14,0,\"float-right\"],[12],[2,\"\\n    \"],[8,\"layout/button\",[[24,\"data-test-signout-link\",\"\"],[16,\"onclick\",[30,[36,0],[[32,0],[32,1]],null]]],[[\"@type\"],[\"plain\"]],[[\"default\"],[{\"statements\":[[2,\"Sign Out\"]],\"parameters\":[]}]]],[2,\"\\n  \"],[13],[2,\"\\n\"],[13],[2,\"\\n\"],[18,2,null]],\"hasEval\":false,\"upvars\":[\"action\"]}",
    meta: {
      moduleName: "aboyeur/components/layout/nav.hbs"
    }
  });

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());

  _exports.default = _default;
});