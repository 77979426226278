define("aboyeur/templates/components/printer", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "42dM+nNU",
    "block": "{\"symbols\":[\"@printer\"],\"statements\":[[10,\"div\"],[14,0,\"border-black border px-4 pt-3 pb-3 mb-2 mx-auto rounded flex my-2\"],[12],[2,\"\\n   \"],[10,\"div\"],[14,0,\"w-1/12 self-center h-auto\"],[12],[10,\"i\"],[14,0,\"fa fa-print\"],[14,\"aria-hidden\",\"true\"],[12],[13],[13],[2,\"\\n   \"],[10,\"div\"],[14,0,\"w-6/12 h-auto font-semibold text-black\"],[12],[1,[32,1,[\"partner\",\"name\"]]],[2,\" \"],[13],[2,\"\\n   \"],[10,\"div\"],[14,0,\"w-4/12 h-auto text-black font-bold\"],[12],[2,\"\\n      \"],[1,[30,[36,0],[[32,1,[\"status\",\"success\"]],\"Online\",\"Offline\"],null]],[2,\"\\n      \"],[10,\"span\"],[14,0,\"font-normal italic\"],[12],[2,\"\\n\"],[6,[37,0],[[32,1,[\"status\",\"success\"]]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"         as of:\\n         \"],[8,\"status-time\",[],[[\"@statusTime\"],[[32,1,[\"onlineAt\"]]]],[[\"default\"],[{\"statements\":[],\"parameters\":[]}]]],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"         Last known online at:\\n         \"],[8,\"status-time\",[],[[\"@statusTime\"],[[32,1,[\"onlineAt\"]]]],[[\"default\"],[{\"statements\":[],\"parameters\":[]}]]],[2,\"\\n         Discovered offline at:\\n         \"],[8,\"status-time\",[],[[\"@statusTime\"],[[32,1,[\"offlineAt\"]]]],[[\"default\"],[{\"statements\":[],\"parameters\":[]}]]],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"      \"],[13],[2,\"\\n   \"],[13],[2,\"\\n   \"],[10,\"div\"],[14,0,\"w-1/12 self-center h-auto\"],[12],[2,\"\\n      \"],[10,\"div\"],[15,0,[31,[\"float-right fa fa-times-circle fa-3x text-red\\n         \",[30,[36,0],[[32,1,[\"status\",\"success\"]],\"fa-check-circle text-green\"],null]]]],[14,\"aria-hidden\",\"true\"],[12],[2,\"\\n      \"],[13],[2,\"\\n   \"],[13],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[\"if\"]}",
    "meta": {
      "moduleName": "aboyeur/templates/components/printer.hbs"
    }
  });

  _exports.default = _default;
});