define("aboyeur/mirage/config", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _default;

  function _default() {
    this.urlPrefix = 'http://localhost:3000';
    this.namespace = '/api/v3';
    this.passthrough('http://localhost:3000/api/v2/authentications');
    this.passthrough('http://localhost:3000/api/v3/users/me');
    this.passthrough('http://localhost:3000/api/v2/users/plaunch_me');
    this.get('/partners', () => {
      return {
        "data": [{
          "id": "3425",
          "type": "partner",
          "attributes": {
            "id": 3425,
            "name": "Buffalo Wild Wings - Taylor - Printer Sandbox"
          },
          "relationships": {
            "order_platforms": {
              "data": [{
                "id": "8420",
                "type": "order_platform"
              }, {
                "id": "8421",
                "type": "order_platform"
              }, {
                "id": "10066",
                "type": "order_platform"
              }]
            },
            "menu_mapping_menu": {
              "data": null
            },
            "menu_import_status": {
              "data": {
                "id": "7E74CE2C-0957-4CA0-BA61-7A024D1126F1",
                "type": "status"
              }
            }
          }
        }, {
          "id": "3469",
          "type": "partner",
          "attributes": {
            "id": 3469,
            "name": "Aloha Poke - 19thDupontCircle"
          },
          "relationships": {
            "order_platforms": {
              "data": [{
                "id": "8638",
                "type": "order_platform"
              }, {
                "id": "8639",
                "type": "order_platform"
              }, {
                "id": "8678",
                "type": "order_platform"
              }, {
                "id": "8699",
                "type": "order_platform"
              }, {
                "id": "11478",
                "type": "order_platform"
              }]
            },
            "menu_mapping_menu": {
              "data": null
            },
            "menu_import_status": {
              "data": {
                "id": "7B279B57-D305-40BB-92CA-FB2811C0F1F5",
                "type": "status"
              }
            }
          }
        }, {
          "id": "3493",
          "type": "partner",
          "attributes": {
            "id": 3493,
            "name": "Akashi - Brickell"
          },
          "relationships": {
            "order_platforms": {
              "data": [{
                "id": "8675",
                "type": "order_platform"
              }, {
                "id": "8676",
                "type": "order_platform"
              }, {
                "id": "9933",
                "type": "order_platform"
              }]
            },
            "menu_mapping_menu": {
              "data": null
            },
            "menu_import_status": {
              "data": {
                "id": "D043BB2D-7BB7-441D-9C7B-678D37BCC134",
                "type": "status"
              }
            }
          }
        }, {
          "id": "3532",
          "type": "partner",
          "attributes": {
            "id": 3532,
            "name": "Aloha Poke - Belmont"
          },
          "relationships": {
            "order_platforms": {
              "data": [{
                "id": "8977",
                "type": "order_platform"
              }, {
                "id": "8979",
                "type": "order_platform"
              }, {
                "id": "9226",
                "type": "order_platform"
              }, {
                "id": "9482",
                "type": "order_platform"
              }, {
                "id": "9483",
                "type": "order_platform"
              }, {
                "id": "9485",
                "type": "order_platform"
              }, {
                "id": "9486",
                "type": "order_platform"
              }]
            },
            "menu_mapping_menu": {
              "data": null
            },
            "menu_import_status": {
              "data": {
                "id": "D94FA1E1-06FC-40BE-8506-E542AEC9AEC7",
                "type": "status"
              }
            }
          }
        }, {
          "id": "3606",
          "type": "partner",
          "attributes": {
            "id": 3606,
            "name": "Coco and Cru - Broadway"
          },
          "relationships": {
            "order_platforms": {
              "data": [{
                "id": "9053",
                "type": "order_platform"
              }, {
                "id": "9058",
                "type": "order_platform"
              }, {
                "id": "9059",
                "type": "order_platform"
              }, {
                "id": "9060",
                "type": "order_platform"
              }, {
                "id": "9237",
                "type": "order_platform"
              }]
            },
            "menu_mapping_menu": {
              "data": null
            },
            "menu_import_status": {
              "data": {
                "id": "BEF0F3BA-F895-48A5-9EFA-45B6C94D632F",
                "type": "status"
              }
            }
          }
        }, {
          "id": "3635",
          "type": "partner",
          "attributes": {
            "id": 3635,
            "name": "5411 Empanadas - North"
          },
          "relationships": {
            "order_platforms": {
              "data": [{
                "id": "9143",
                "type": "order_platform"
              }, {
                "id": "9557",
                "type": "order_platform"
              }, {
                "id": "9558",
                "type": "order_platform"
              }, {
                "id": "9559",
                "type": "order_platform"
              }, {
                "id": "9560",
                "type": "order_platform"
              }, {
                "id": "9561",
                "type": "order_platform"
              }, {
                "id": "10036",
                "type": "order_platform"
              }, {
                "id": "11189",
                "type": "order_platform"
              }]
            },
            "menu_mapping_menu": {
              "data": null
            },
            "menu_import_status": {
              "data": {
                "id": "C52389A5-AF67-40D3-95A2-0086631D9195",
                "type": "status"
              }
            }
          }
        }, {
          "id": "4324",
          "type": "partner",
          "attributes": {
            "id": 4324,
            "name": "Lost Dog Cafe - ColumbiaArlington"
          },
          "relationships": {
            "order_platforms": {
              "data": [{
                "id": "10805",
                "type": "order_platform"
              }, {
                "id": "10806",
                "type": "order_platform"
              }, {
                "id": "10807",
                "type": "order_platform"
              }, {
                "id": "10808",
                "type": "order_platform"
              }, {
                "id": "12215",
                "type": "order_platform"
              }, {
                "id": "12216",
                "type": "order_platform"
              }, {
                "id": "14796",
                "type": "order_platform"
              }]
            },
            "menu_mapping_menu": {
              "data": null
            },
            "menu_import_status": {
              "data": {
                "id": "6EB557E5-2438-42A5-AE59-E1A1C00C4189",
                "type": "status"
              }
            }
          }
        }, {
          "id": "4326",
          "type": "partner",
          "attributes": {
            "id": 4326,
            "name": "Lost Dog Cafe - AndersonMcLean"
          },
          "relationships": {
            "order_platforms": {
              "data": [{
                "id": "10815",
                "type": "order_platform"
              }, {
                "id": "10817",
                "type": "order_platform"
              }, {
                "id": "10818",
                "type": "order_platform"
              }, {
                "id": "14794",
                "type": "order_platform"
              }]
            },
            "menu_mapping_menu": {
              "data": null
            },
            "menu_import_status": {
              "data": {
                "id": "ABDDF724-9453-4ABA-985E-B40A50AB5099",
                "type": "status"
              }
            }
          }
        }, {
          "id": "4327",
          "type": "partner",
          "attributes": {
            "id": 4327,
            "name": "Lost Dog Cafe - Merrilee Fairfax"
          },
          "relationships": {
            "order_platforms": {
              "data": [{
                "id": "10819",
                "type": "order_platform"
              }, {
                "id": "10820",
                "type": "order_platform"
              }, {
                "id": "10821",
                "type": "order_platform"
              }, {
                "id": "12819",
                "type": "order_platform"
              }, {
                "id": "15001",
                "type": "order_platform"
              }]
            },
            "menu_mapping_menu": {
              "data": null
            },
            "menu_import_status": {
              "data": {
                "id": "1AF387AF-B5CC-4D3D-8A56-BCF216786FA1",
                "type": "status"
              }
            }
          }
        }, {
          "id": "4696",
          "type": "partner",
          "attributes": {
            "id": 4696,
            "name": "Condado Tacos Commons - HighColumbus"
          },
          "relationships": {
            "order_platforms": {
              "data": [{
                "id": "11574",
                "type": "order_platform"
              }, {
                "id": "12167",
                "type": "order_platform"
              }, {
                "id": "21955",
                "type": "order_platform"
              }]
            },
            "menu_mapping_menu": {
              "data": null
            },
            "menu_import_status": {
              "data": {
                "id": "2C308F7C-0DAF-4DE1-8B69-BDB9FB6AFEB8",
                "type": "status"
              }
            }
          }
        }, {
          "id": "4698",
          "type": "partner",
          "attributes": {
            "id": 4698,
            "name": "Condado Tacos Clintonville - HighColumbus"
          },
          "relationships": {
            "order_platforms": {
              "data": [{
                "id": "12155",
                "type": "order_platform"
              }, {
                "id": "12387",
                "type": "order_platform"
              }]
            },
            "menu_mapping_menu": {
              "data": null
            },
            "menu_import_status": {
              "data": {
                "id": "B1AB98F3-16B4-4D1C-8A8B-F46DFB012AB1",
                "type": "status"
              }
            }
          }
        }, {
          "id": "4699",
          "type": "partner",
          "attributes": {
            "id": 4699,
            "name": "Condado Tacos Lawrenceville - ButlerPittsburgh"
          },
          "relationships": {
            "order_platforms": {
              "data": [{
                "id": "12156",
                "type": "order_platform"
              }]
            },
            "menu_mapping_menu": {
              "data": null
            },
            "menu_import_status": {
              "data": {
                "id": "35A3A035-ABAE-4BDE-96BF-9B332BB63C0B",
                "type": "status"
              }
            }
          }
        }, {
          "id": "4759",
          "type": "partner",
          "attributes": {
            "id": 4759,
            "name": "Condado Tacos Short North - HighColumbus"
          },
          "relationships": {
            "order_platforms": {
              "data": [{
                "id": "12145",
                "type": "order_platform"
              }, {
                "id": "12441",
                "type": "order_platform"
              }]
            },
            "menu_mapping_menu": {
              "data": null
            },
            "menu_import_status": {
              "data": {
                "id": "EDAB0DCF-DF17-499B-91A9-7ED62A110273",
                "type": "status"
              }
            }
          }
        }, {
          "id": "4938",
          "type": "partner",
          "attributes": {
            "id": 4938,
            "name": "Condado Tacos The Banks - Freedom WayCincinnati"
          },
          "relationships": {
            "order_platforms": {
              "data": [{
                "id": "12157",
                "type": "order_platform"
              }, {
                "id": "12674",
                "type": "order_platform"
              }, {
                "id": "21959",
                "type": "order_platform"
              }]
            },
            "menu_mapping_menu": {
              "data": null
            },
            "menu_import_status": {
              "data": {
                "id": "585DF2A8-31A5-40C4-B908-621361633F5F",
                "type": "status"
              }
            }
          }
        }, {
          "id": "4939",
          "type": "partner",
          "attributes": {
            "id": 4939,
            "name": "Condado Tacos Easton - FenlonColumbus"
          },
          "relationships": {
            "order_platforms": {
              "data": [{
                "id": "12181",
                "type": "order_platform"
              }, {
                "id": "12425",
                "type": "order_platform"
              }]
            },
            "menu_mapping_menu": {
              "data": null
            },
            "menu_import_status": {
              "data": {
                "id": "8B5EB0E5-F432-499A-AC5A-32E0A694B764",
                "type": "status"
              }
            }
          }
        }, {
          "id": "4940",
          "type": "partner",
          "attributes": {
            "id": 4940,
            "name": "Condado Tacos Polaris - LyraColumbus"
          },
          "relationships": {
            "order_platforms": {
              "data": [{
                "id": "12196",
                "type": "order_platform"
              }, {
                "id": "12443",
                "type": "order_platform"
              }]
            },
            "menu_mapping_menu": {
              "data": null
            },
            "menu_import_status": {
              "data": {
                "id": "6A0C02C3-B097-4744-93FF-0CA08800FCA8",
                "type": "status"
              }
            }
          }
        }, {
          "id": "4941",
          "type": "partner",
          "attributes": {
            "id": 4941,
            "name": "Condado Tacos Pinecrest - ParkOrange Village"
          },
          "relationships": {
            "order_platforms": {
              "data": [{
                "id": "12194",
                "type": "order_platform"
              }]
            },
            "menu_mapping_menu": {
              "data": null
            },
            "menu_import_status": {
              "data": {
                "id": "57A0247C-C848-49E0-B709-91D5F3CA9C59",
                "type": "status"
              }
            }
          }
        }, {
          "id": "5153",
          "type": "partner",
          "attributes": {
            "id": 5153,
            "name": "Condado Tacos - MassachusettsIndianapolis"
          },
          "relationships": {
            "order_platforms": {
              "data": [{
                "id": "12682",
                "type": "order_platform"
              }, {
                "id": "21960",
                "type": "order_platform"
              }]
            },
            "menu_mapping_menu": {
              "data": null
            },
            "menu_import_status": {
              "data": {
                "id": "5DD78286-DC81-41F8-A22B-6DC09C61AC97",
                "type": "status"
              }
            }
          }
        }, {
          "id": "5162",
          "type": "partner",
          "attributes": {
            "id": 5162,
            "name": "5411 Empanadas - Franklin"
          },
          "relationships": {
            "order_platforms": {
              "data": [{
                "id": "12701",
                "type": "order_platform"
              }, {
                "id": "12776",
                "type": "order_platform"
              }, {
                "id": "12777",
                "type": "order_platform"
              }, {
                "id": "12778",
                "type": "order_platform"
              }, {
                "id": "12779",
                "type": "order_platform"
              }, {
                "id": "12781",
                "type": "order_platform"
              }]
            },
            "menu_mapping_menu": {
              "data": null
            },
            "menu_import_status": {
              "data": {
                "id": "31C8F7D6-D228-4410-8F1C-070FEBB291E7",
                "type": "status"
              }
            }
          }
        }, {
          "id": "5165",
          "type": "partner",
          "attributes": {
            "id": 5165,
            "name": "5411 Empanadas - Clark"
          },
          "relationships": {
            "order_platforms": {
              "data": [{
                "id": "12702",
                "type": "order_platform"
              }, {
                "id": "12718",
                "type": "order_platform"
              }, {
                "id": "12724",
                "type": "order_platform"
              }, {
                "id": "12725",
                "type": "order_platform"
              }, {
                "id": "12726",
                "type": "order_platform"
              }, {
                "id": "12727",
                "type": "order_platform"
              }]
            },
            "menu_mapping_menu": {
              "data": null
            },
            "menu_import_status": {
              "data": {
                "id": "C6C74050-549C-47BB-AB86-CA0EA30F28C6",
                "type": "status"
              }
            }
          }
        }, {
          "id": "5212",
          "type": "partner",
          "attributes": {
            "id": 5212,
            "name": "Lost Dog Cafe - WashingtonNArlington"
          },
          "relationships": {
            "order_platforms": {
              "data": [{
                "id": "12869",
                "type": "order_platform"
              }, {
                "id": "12870",
                "type": "order_platform"
              }, {
                "id": "12872",
                "type": "order_platform"
              }, {
                "id": "12873",
                "type": "order_platform"
              }, {
                "id": "13207",
                "type": "order_platform"
              }, {
                "id": "15000",
                "type": "order_platform"
              }]
            },
            "menu_mapping_menu": {
              "data": null
            },
            "menu_import_status": {
              "data": {
                "id": "344D5D57-C0C7-4C2D-BDCD-20207711BF17",
                "type": "status"
              }
            }
          }
        }, {
          "id": "5355",
          "type": "partner",
          "attributes": {
            "id": 5355,
            "name": "5411 Empanadas - Division"
          },
          "relationships": {
            "order_platforms": {
              "data": [{
                "id": "13283",
                "type": "order_platform"
              }, {
                "id": "13284",
                "type": "order_platform"
              }, {
                "id": "13285",
                "type": "order_platform"
              }, {
                "id": "13286",
                "type": "order_platform"
              }, {
                "id": "13287",
                "type": "order_platform"
              }, {
                "id": "13303",
                "type": "order_platform"
              }, {
                "id": "16715",
                "type": "order_platform"
              }]
            },
            "menu_mapping_menu": {
              "data": null
            },
            "menu_import_status": {
              "data": {
                "id": "1F654302-BF73-4DE4-93D3-08B8F3004C96",
                "type": "status"
              }
            }
          }
        }, {
          "id": "5375",
          "type": "partner",
          "attributes": {
            "id": 5375,
            "name": "5411 Empanadas - Southport"
          },
          "relationships": {
            "order_platforms": {
              "data": [{
                "id": "13302",
                "type": "order_platform"
              }, {
                "id": "13509",
                "type": "order_platform"
              }, {
                "id": "13510",
                "type": "order_platform"
              }, {
                "id": "13511",
                "type": "order_platform"
              }, {
                "id": "13512",
                "type": "order_platform"
              }, {
                "id": "13628",
                "type": "order_platform"
              }, {
                "id": "14106",
                "type": "order_platform"
              }]
            },
            "menu_mapping_menu": {
              "data": null
            },
            "menu_import_status": {
              "data": {
                "id": "FC09FF58-659D-424C-AE3F-ADEF2E197BBE",
                "type": "status"
              }
            }
          }
        }, {
          "id": "5397",
          "type": "partner",
          "attributes": {
            "id": 5397,
            "name": "Lost Dog Cafe - HenryAlexandria"
          },
          "relationships": {
            "order_platforms": {
              "data": [{
                "id": "13431",
                "type": "order_platform"
              }, {
                "id": "13507",
                "type": "order_platform"
              }, {
                "id": "15449",
                "type": "order_platform"
              }]
            },
            "menu_mapping_menu": {
              "data": null
            },
            "menu_import_status": {
              "data": {
                "id": "B8BE65AE-F87D-49EA-AF99-7F1712F56D69",
                "type": "status"
              }
            }
          }
        }, {
          "id": "5852",
          "type": "partner",
          "attributes": {
            "id": 5852,
            "name": "Aloha Poke - DavisEvanston"
          },
          "relationships": {
            "order_platforms": {
              "data": [{
                "id": "14958",
                "type": "order_platform"
              }, {
                "id": "15216",
                "type": "order_platform"
              }, {
                "id": "16266",
                "type": "order_platform"
              }, {
                "id": "16267",
                "type": "order_platform"
              }, {
                "id": "19875",
                "type": "order_platform"
              }, {
                "id": "19978",
                "type": "order_platform"
              }]
            },
            "menu_mapping_menu": {
              "data": null
            },
            "menu_import_status": {
              "data": {
                "id": "3432CA0C-8128-4801-B636-D58B494DFCDA",
                "type": "status"
              }
            }
          }
        }],
        "included": [{
          "id": "7E74CE2C-0957-4CA0-BA61-7A024D1126F1",
          "type": "status",
          "attributes": {
            "success": true,
            "timestamp": "2020-06-11T14:04:23.926-05:00",
            "category": "menu_import"
          }
        }, {
          "id": "8420",
          "type": "order_platform",
          "attributes": {
            "id": 8420,
            "name": "Garcon"
          },
          "relationships": {
            "partner": {
              "data": {
                "id": "3425",
                "type": "partner"
              }
            },
            "menu_mapping_menu": {
              "data": null
            },
            "menu_publish_status": {
              "data": {
                "id": "7C3E4B74-A893-45EF-A3A5-D69B77AE2910",
                "type": "status"
              }
            }
          }
        }, {
          "id": "8421",
          "type": "order_platform",
          "attributes": {
            "id": 8421,
            "name": "DoorDash"
          },
          "relationships": {
            "partner": {
              "data": {
                "id": "3425",
                "type": "partner"
              }
            },
            "menu_mapping_menu": {
              "data": null
            },
            "menu_publish_status": {
              "data": {
                "id": "771A4EFC-40A3-44D7-9021-DBD2BB858FB7",
                "type": "status"
              }
            }
          }
        }, {
          "id": "10066",
          "type": "order_platform",
          "attributes": {
            "id": 10066,
            "name": "UberEats"
          },
          "relationships": {
            "partner": {
              "data": {
                "id": "3425",
                "type": "partner"
              }
            },
            "menu_mapping_menu": {
              "data": null
            },
            "menu_publish_status": {
              "data": {
                "id": "869B1408-A21F-4A29-8B1D-FEFE1F1A85D1",
                "type": "status"
              }
            }
          }
        }, {
          "id": "7C3E4B74-A893-45EF-A3A5-D69B77AE2910",
          "type": "status",
          "attributes": {
            "success": true,
            "timestamp": "2020-06-11T14:04:24.793-05:00",
            "category": "menu_publish"
          }
        }, {
          "id": "771A4EFC-40A3-44D7-9021-DBD2BB858FB7",
          "type": "status",
          "attributes": {
            "success": true,
            "timestamp": "2020-06-11T14:04:24.813-05:00",
            "category": "menu_publish"
          }
        }, {
          "id": "869B1408-A21F-4A29-8B1D-FEFE1F1A85D1",
          "type": "status",
          "attributes": {
            "success": true,
            "timestamp": "2020-06-11T14:04:25.519-05:00",
            "category": "menu_publish"
          }
        }, {
          "id": "7B279B57-D305-40BB-92CA-FB2811C0F1F5",
          "type": "status",
          "attributes": {
            "success": true,
            "timestamp": "2020-06-11T14:04:24.013-05:00",
            "category": "menu_import"
          }
        }, {
          "id": "8638",
          "type": "order_platform",
          "attributes": {
            "id": 8638,
            "name": "Postmates"
          },
          "relationships": {
            "partner": {
              "data": {
                "id": "3469",
                "type": "partner"
              }
            },
            "menu_mapping_menu": {
              "data": null
            },
            "menu_publish_status": {
              "data": {
                "id": "5FC008A4-F30F-42E7-9251-2E2A668D8685",
                "type": "status"
              }
            }
          }
        }, {
          "id": "8639",
          "type": "order_platform",
          "attributes": {
            "id": 8639,
            "name": "UberEats"
          },
          "relationships": {
            "partner": {
              "data": {
                "id": "3469",
                "type": "partner"
              }
            },
            "menu_mapping_menu": {
              "data": null
            },
            "menu_publish_status": {
              "data": {
                "id": "38F1B5F8-2228-4EEB-BAF8-50DB3FFD951F",
                "type": "status"
              }
            }
          }
        }, {
          "id": "8678",
          "type": "order_platform",
          "attributes": {
            "id": 8678,
            "name": "Garcon"
          },
          "relationships": {
            "partner": {
              "data": {
                "id": "3469",
                "type": "partner"
              }
            },
            "menu_mapping_menu": {
              "data": null
            },
            "menu_publish_status": {
              "data": {
                "id": "5E8CEB79-DE90-4D60-BADF-CF69F34F6D92",
                "type": "status"
              }
            }
          }
        }, {
          "id": "8699",
          "type": "order_platform",
          "attributes": {
            "id": 8699,
            "name": "DoorDash"
          },
          "relationships": {
            "partner": {
              "data": {
                "id": "3469",
                "type": "partner"
              }
            },
            "menu_mapping_menu": {
              "data": null
            },
            "menu_publish_status": {
              "data": {
                "id": "0B95DDEB-F74D-45AD-BC84-12E74FF66FC2",
                "type": "status"
              }
            }
          }
        }, {
          "id": "11478",
          "type": "order_platform",
          "attributes": {
            "id": 11478,
            "name": "Ritual"
          },
          "relationships": {
            "partner": {
              "data": {
                "id": "3469",
                "type": "partner"
              }
            },
            "menu_mapping_menu": {
              "data": null
            },
            "menu_publish_status": {
              "data": {
                "id": "E119306A-5798-491E-B3BC-BDA1AB25D55C",
                "type": "status"
              }
            }
          }
        }, {
          "id": "5FC008A4-F30F-42E7-9251-2E2A668D8685",
          "type": "status",
          "attributes": {
            "success": true,
            "timestamp": "2020-06-11T14:04:24.823-05:00",
            "category": "menu_publish"
          }
        }, {
          "id": "38F1B5F8-2228-4EEB-BAF8-50DB3FFD951F",
          "type": "status",
          "attributes": {
            "success": true,
            "timestamp": "2020-06-11T14:04:24.862-05:00",
            "category": "menu_publish"
          }
        }, {
          "id": "5E8CEB79-DE90-4D60-BADF-CF69F34F6D92",
          "type": "status",
          "attributes": {
            "success": true,
            "timestamp": "2020-06-11T14:04:24.929-05:00",
            "category": "menu_publish"
          }
        }, {
          "id": "0B95DDEB-F74D-45AD-BC84-12E74FF66FC2",
          "type": "status",
          "attributes": {
            "success": true,
            "timestamp": "2020-06-11T14:04:24.972-05:00",
            "category": "menu_publish"
          }
        }, {
          "id": "E119306A-5798-491E-B3BC-BDA1AB25D55C",
          "type": "status",
          "attributes": {
            "success": true,
            "timestamp": "2020-06-11T14:04:25.823-05:00",
            "category": "menu_publish"
          }
        }, {
          "id": "D043BB2D-7BB7-441D-9C7B-678D37BCC134",
          "type": "status",
          "attributes": {
            "success": true,
            "timestamp": "2020-06-11T14:04:24.023-05:00",
            "category": "menu_import"
          }
        }, {
          "id": "8675",
          "type": "order_platform",
          "attributes": {
            "id": 8675,
            "name": "Grubhub"
          },
          "relationships": {
            "partner": {
              "data": {
                "id": "3493",
                "type": "partner"
              }
            },
            "menu_mapping_menu": {
              "data": null
            },
            "menu_publish_status": {
              "data": {
                "id": "CEBF7AD4-9733-49CD-82E8-D4290A3A469F",
                "type": "status"
              }
            }
          }
        }, {
          "id": "8676",
          "type": "order_platform",
          "attributes": {
            "id": 8676,
            "name": "UberEats"
          },
          "relationships": {
            "partner": {
              "data": {
                "id": "3493",
                "type": "partner"
              }
            },
            "menu_mapping_menu": {
              "data": null
            },
            "menu_publish_status": {
              "data": {
                "id": "77DD381E-CB1D-4591-A7EF-6B7064A9F01E",
                "type": "status"
              }
            }
          }
        }, {
          "id": "9933",
          "type": "order_platform",
          "attributes": {
            "id": 9933,
            "name": "Postmates"
          },
          "relationships": {
            "partner": {
              "data": {
                "id": "3493",
                "type": "partner"
              }
            },
            "menu_mapping_menu": {
              "data": null
            },
            "menu_publish_status": {
              "data": {
                "id": "6EF401F1-DC1D-4E7E-9A3C-C4EC3D91AFB3",
                "type": "status"
              }
            }
          }
        }, {
          "id": "CEBF7AD4-9733-49CD-82E8-D4290A3A469F",
          "type": "status",
          "attributes": {
            "success": true,
            "timestamp": "2020-06-11T14:04:24.876-05:00",
            "category": "menu_publish"
          }
        }, {
          "id": "77DD381E-CB1D-4591-A7EF-6B7064A9F01E",
          "type": "status",
          "attributes": {
            "success": true,
            "timestamp": "2020-06-11T14:04:24.919-05:00",
            "category": "menu_publish"
          }
        }, {
          "id": "6EF401F1-DC1D-4E7E-9A3C-C4EC3D91AFB3",
          "type": "status",
          "attributes": {
            "success": true,
            "timestamp": "2020-06-11T14:04:25.470-05:00",
            "category": "menu_publish"
          }
        }, {
          "id": "D94FA1E1-06FC-40BE-8506-E542AEC9AEC7",
          "type": "status",
          "attributes": {
            "success": true,
            "timestamp": "2020-06-11T14:04:24.063-05:00",
            "category": "menu_import"
          }
        }, {
          "id": "8977",
          "type": "order_platform",
          "attributes": {
            "id": 8977,
            "name": "Postmates"
          },
          "relationships": {
            "partner": {
              "data": {
                "id": "3532",
                "type": "partner"
              }
            },
            "menu_mapping_menu": {
              "data": null
            },
            "menu_publish_status": {
              "data": {
                "id": "00364C96-D519-4073-AB72-BBD895799BED",
                "type": "status"
              }
            }
          }
        }, {
          "id": "8979",
          "type": "order_platform",
          "attributes": {
            "id": 8979,
            "name": "DoorDash"
          },
          "relationships": {
            "partner": {
              "data": {
                "id": "3532",
                "type": "partner"
              }
            },
            "menu_mapping_menu": {
              "data": null
            },
            "menu_publish_status": {
              "data": {
                "id": "5A93B5DF-F5D8-49DF-9E7A-4268B7077071",
                "type": "status"
              }
            }
          }
        }, {
          "id": "9226",
          "type": "order_platform",
          "attributes": {
            "id": 9226,
            "name": "Garcon"
          },
          "relationships": {
            "partner": {
              "data": {
                "id": "3532",
                "type": "partner"
              }
            },
            "menu_mapping_menu": {
              "data": null
            },
            "menu_publish_status": {
              "data": {
                "id": "E20ED0CD-8252-4DE1-8605-AB63646571AA",
                "type": "status"
              }
            }
          }
        }, {
          "id": "9482",
          "type": "order_platform",
          "attributes": {
            "id": 9482,
            "name": "Caviar"
          },
          "relationships": {
            "partner": {
              "data": {
                "id": "3532",
                "type": "partner"
              }
            },
            "menu_mapping_menu": {
              "data": null
            },
            "menu_publish_status": {
              "data": {
                "id": "F3A0B663-F084-4E8C-89D9-2EA2C2813B3D",
                "type": "status"
              }
            }
          }
        }, {
          "id": "9483",
          "type": "order_platform",
          "attributes": {
            "id": 9483,
            "name": "UberEats"
          },
          "relationships": {
            "partner": {
              "data": {
                "id": "3532",
                "type": "partner"
              }
            },
            "menu_mapping_menu": {
              "data": null
            },
            "menu_publish_status": {
              "data": {
                "id": "B81D5B9A-B982-45B6-B380-9388EC6EFE36",
                "type": "status"
              }
            }
          }
        }, {
          "id": "9485",
          "type": "order_platform",
          "attributes": {
            "id": 9485,
            "name": "Grubhub"
          },
          "relationships": {
            "partner": {
              "data": {
                "id": "3532",
                "type": "partner"
              }
            },
            "menu_mapping_menu": {
              "data": null
            },
            "menu_publish_status": {
              "data": {
                "id": "F34F2087-C862-4E1D-8C6C-76D38B03719F",
                "type": "status"
              }
            }
          }
        }, {
          "id": "9486",
          "type": "order_platform",
          "attributes": {
            "id": 9486,
            "name": "Ritual"
          },
          "relationships": {
            "partner": {
              "data": {
                "id": "3532",
                "type": "partner"
              }
            },
            "menu_mapping_menu": {
              "data": null
            },
            "menu_publish_status": {
              "data": {
                "id": "078352CA-4402-4819-989E-64C1914809FE",
                "type": "status"
              }
            }
          }
        }, {
          "id": "00364C96-D519-4073-AB72-BBD895799BED",
          "type": "status",
          "attributes": {
            "success": true,
            "timestamp": "2020-06-11T14:04:25.013-05:00",
            "category": "menu_publish"
          }
        }, {
          "id": "5A93B5DF-F5D8-49DF-9E7A-4268B7077071",
          "type": "status",
          "attributes": {
            "success": true,
            "timestamp": "2020-06-11T14:04:25.023-05:00",
            "category": "menu_publish"
          }
        }, {
          "id": "E20ED0CD-8252-4DE1-8605-AB63646571AA",
          "type": "status",
          "attributes": {
            "success": true,
            "timestamp": "2020-06-11T14:04:25.176-05:00",
            "category": "menu_publish"
          }
        }, {
          "id": "F3A0B663-F084-4E8C-89D9-2EA2C2813B3D",
          "type": "status",
          "attributes": {
            "success": true,
            "timestamp": "2020-06-11T14:04:25.226-05:00",
            "category": "menu_publish"
          }
        }, {
          "id": "B81D5B9A-B982-45B6-B380-9388EC6EFE36",
          "type": "status",
          "attributes": {
            "success": true,
            "timestamp": "2020-06-11T14:04:25.270-05:00",
            "category": "menu_publish"
          }
        }, {
          "id": "F34F2087-C862-4E1D-8C6C-76D38B03719F",
          "type": "status",
          "attributes": {
            "success": true,
            "timestamp": "2020-06-11T14:04:25.280-05:00",
            "category": "menu_publish"
          }
        }, {
          "id": "078352CA-4402-4819-989E-64C1914809FE",
          "type": "status",
          "attributes": {
            "success": true,
            "timestamp": "2020-06-11T14:04:25.320-05:00",
            "category": "menu_publish"
          }
        }, {
          "id": "BEF0F3BA-F895-48A5-9EFA-45B6C94D632F",
          "type": "status",
          "attributes": {
            "success": true,
            "timestamp": "2020-06-11T14:04:24.073-05:00",
            "category": "menu_import"
          }
        }, {
          "id": "9053",
          "type": "order_platform",
          "attributes": {
            "id": 9053,
            "name": "Caviar"
          },
          "relationships": {
            "partner": {
              "data": {
                "id": "3606",
                "type": "partner"
              }
            },
            "menu_mapping_menu": {
              "data": null
            },
            "menu_publish_status": {
              "data": {
                "id": "D2C91B47-765A-4BFD-9B4D-86080684EC81",
                "type": "status"
              }
            }
          }
        }, {
          "id": "9058",
          "type": "order_platform",
          "attributes": {
            "id": 9058,
            "name": "Grubhub"
          },
          "relationships": {
            "partner": {
              "data": {
                "id": "3606",
                "type": "partner"
              }
            },
            "menu_mapping_menu": {
              "data": null
            },
            "menu_publish_status": {
              "data": {
                "id": "0EE48572-5558-4E19-8CB9-9B7A49465C78",
                "type": "status"
              }
            }
          }
        }, {
          "id": "9059",
          "type": "order_platform",
          "attributes": {
            "id": 9059,
            "name": "Postmates"
          },
          "relationships": {
            "partner": {
              "data": {
                "id": "3606",
                "type": "partner"
              }
            },
            "menu_mapping_menu": {
              "data": null
            },
            "menu_publish_status": {
              "data": {
                "id": "22992E12-3802-4DF5-ABB7-8EBB20A0516A",
                "type": "status"
              }
            }
          }
        }, {
          "id": "9060",
          "type": "order_platform",
          "attributes": {
            "id": 9060,
            "name": "UberEats"
          },
          "relationships": {
            "partner": {
              "data": {
                "id": "3606",
                "type": "partner"
              }
            },
            "menu_mapping_menu": {
              "data": null
            },
            "menu_publish_status": {
              "data": {
                "id": "A39BDB9F-00E2-4551-9906-6031E2F0D727",
                "type": "status"
              }
            }
          }
        }, {
          "id": "9237",
          "type": "order_platform",
          "attributes": {
            "id": 9237,
            "name": "Garcon"
          },
          "relationships": {
            "partner": {
              "data": {
                "id": "3606",
                "type": "partner"
              }
            },
            "menu_mapping_menu": {
              "data": null
            },
            "menu_publish_status": {
              "data": {
                "id": "88DC11B0-01F1-4247-868C-0559ED8E2409",
                "type": "status"
              }
            }
          }
        }, {
          "id": "D2C91B47-765A-4BFD-9B4D-86080684EC81",
          "type": "status",
          "attributes": {
            "success": true,
            "timestamp": "2020-06-11T14:04:25.063-05:00",
            "category": "menu_publish"
          }
        }, {
          "id": "0EE48572-5558-4E19-8CB9-9B7A49465C78",
          "type": "status",
          "attributes": {
            "success": true,
            "timestamp": "2020-06-11T14:04:25.073-05:00",
            "category": "menu_publish"
          }
        }, {
          "id": "22992E12-3802-4DF5-ABB7-8EBB20A0516A",
          "type": "status",
          "attributes": {
            "success": true,
            "timestamp": "2020-06-11T14:04:25.113-05:00",
            "category": "menu_publish"
          }
        }, {
          "id": "A39BDB9F-00E2-4551-9906-6031E2F0D727",
          "type": "status",
          "attributes": {
            "success": true,
            "timestamp": "2020-06-11T14:04:25.123-05:00",
            "category": "menu_publish"
          }
        }, {
          "id": "88DC11B0-01F1-4247-868C-0559ED8E2409",
          "type": "status",
          "attributes": {
            "success": true,
            "timestamp": "2020-06-11T14:04:25.220-05:00",
            "category": "menu_publish"
          }
        }, {
          "id": "C52389A5-AF67-40D3-95A2-0086631D9195",
          "type": "status",
          "attributes": {
            "success": true,
            "timestamp": "2020-06-11T14:04:24.113-05:00",
            "category": "menu_import"
          }
        }, {
          "id": "9143",
          "type": "order_platform",
          "attributes": {
            "id": 9143,
            "name": "Grubhub"
          },
          "relationships": {
            "partner": {
              "data": {
                "id": "3635",
                "type": "partner"
              }
            },
            "menu_mapping_menu": {
              "data": null
            },
            "menu_publish_status": {
              "data": {
                "id": "89F1E416-2D39-4901-B6E9-54457BA34A07",
                "type": "status"
              }
            }
          }
        }, {
          "id": "9557",
          "type": "order_platform",
          "attributes": {
            "id": 9557,
            "name": "Chownow"
          },
          "relationships": {
            "partner": {
              "data": {
                "id": "3635",
                "type": "partner"
              }
            },
            "menu_mapping_menu": {
              "data": null
            },
            "menu_publish_status": {
              "data": {
                "id": "BFD5E58D-9AF1-49DF-A48E-719C71F61FAF",
                "type": "status"
              }
            }
          }
        }, {
          "id": "9558",
          "type": "order_platform",
          "attributes": {
            "id": 9558,
            "name": "Postmates"
          },
          "relationships": {
            "partner": {
              "data": {
                "id": "3635",
                "type": "partner"
              }
            },
            "menu_mapping_menu": {
              "data": null
            },
            "menu_publish_status": {
              "data": {
                "id": "20D7A315-F279-4C6F-84F1-16970B57A2A6",
                "type": "status"
              }
            }
          }
        }, {
          "id": "9559",
          "type": "order_platform",
          "attributes": {
            "id": 9559,
            "name": "Delivery.com"
          },
          "relationships": {
            "partner": {
              "data": {
                "id": "3635",
                "type": "partner"
              }
            },
            "menu_mapping_menu": {
              "data": null
            },
            "menu_publish_status": {
              "data": {
                "id": "D78EAAFA-6C4B-42C9-A4BF-52BEC2DE6E44",
                "type": "status"
              }
            }
          }
        }, {
          "id": "9560",
          "type": "order_platform",
          "attributes": {
            "id": 9560,
            "name": "UberEats"
          },
          "relationships": {
            "partner": {
              "data": {
                "id": "3635",
                "type": "partner"
              }
            },
            "menu_mapping_menu": {
              "data": null
            },
            "menu_publish_status": {
              "data": {
                "id": "EE90E317-2ABB-45B6-8F89-51474844CF66",
                "type": "status"
              }
            }
          }
        }, {
          "id": "9561",
          "type": "order_platform",
          "attributes": {
            "id": 9561,
            "name": "Caviar"
          },
          "relationships": {
            "partner": {
              "data": {
                "id": "3635",
                "type": "partner"
              }
            },
            "menu_mapping_menu": {
              "data": null
            },
            "menu_publish_status": {
              "data": {
                "id": "78B82656-7D13-4F98-AF27-35CFA3A45E60",
                "type": "status"
              }
            }
          }
        }, {
          "id": "10036",
          "type": "order_platform",
          "attributes": {
            "id": 10036,
            "name": "Seamless"
          },
          "relationships": {
            "partner": {
              "data": {
                "id": "3635",
                "type": "partner"
              }
            },
            "menu_mapping_menu": {
              "data": null
            },
            "menu_publish_status": {
              "data": {
                "id": "F2A520A6-EDDC-499E-944F-B817ECAE9455",
                "type": "status"
              }
            }
          }
        }, {
          "id": "11189",
          "type": "order_platform",
          "attributes": {
            "id": 11189,
            "name": "Ritual"
          },
          "relationships": {
            "partner": {
              "data": {
                "id": "3635",
                "type": "partner"
              }
            },
            "menu_mapping_menu": {
              "data": null
            },
            "menu_publish_status": {
              "data": {
                "id": "1CEB3600-0A58-4E03-AD6E-27F5AD4B5D58",
                "type": "status"
              }
            }
          }
        }, {
          "id": "89F1E416-2D39-4901-B6E9-54457BA34A07",
          "type": "status",
          "attributes": {
            "success": true,
            "timestamp": "2020-06-11T14:04:25.163-05:00",
            "category": "menu_publish"
          }
        }, {
          "id": "BFD5E58D-9AF1-49DF-A48E-719C71F61FAF",
          "type": "status",
          "attributes": {
            "success": true,
            "timestamp": "2020-06-11T14:04:25.330-05:00",
            "category": "menu_publish"
          }
        }, {
          "id": "20D7A315-F279-4C6F-84F1-16970B57A2A6",
          "type": "status",
          "attributes": {
            "success": true,
            "timestamp": "2020-06-11T14:04:25.370-05:00",
            "category": "menu_publish"
          }
        }, {
          "id": "D78EAAFA-6C4B-42C9-A4BF-52BEC2DE6E44",
          "type": "status",
          "attributes": {
            "success": true,
            "timestamp": "2020-06-11T14:04:25.376-05:00",
            "category": "menu_publish"
          }
        }, {
          "id": "EE90E317-2ABB-45B6-8F89-51474844CF66",
          "type": "status",
          "attributes": {
            "success": true,
            "timestamp": "2020-06-11T14:04:25.420-05:00",
            "category": "menu_publish"
          }
        }, {
          "id": "78B82656-7D13-4F98-AF27-35CFA3A45E60",
          "type": "status",
          "attributes": {
            "success": true,
            "timestamp": "2020-06-11T14:04:25.430-05:00",
            "category": "menu_publish"
          }
        }, {
          "id": "F2A520A6-EDDC-499E-944F-B817ECAE9455",
          "type": "status",
          "attributes": {
            "success": true,
            "timestamp": "2020-06-11T14:04:25.476-05:00",
            "category": "menu_publish"
          }
        }, {
          "id": "1CEB3600-0A58-4E03-AD6E-27F5AD4B5D58",
          "type": "status",
          "attributes": {
            "success": true,
            "timestamp": "2020-06-11T14:04:25.779-05:00",
            "category": "menu_publish"
          }
        }, {
          "id": "6EB557E5-2438-42A5-AE59-E1A1C00C4189",
          "type": "status",
          "attributes": {
            "success": true,
            "timestamp": "2020-06-11T14:04:24.123-05:00",
            "category": "menu_import"
          }
        }, {
          "id": "10805",
          "type": "order_platform",
          "attributes": {
            "id": 10805,
            "name": "UberEats"
          },
          "relationships": {
            "partner": {
              "data": {
                "id": "4324",
                "type": "partner"
              }
            },
            "menu_mapping_menu": {
              "data": null
            },
            "menu_publish_status": {
              "data": {
                "id": "AAA4AF56-9123-4F85-9809-A98CF55A0733",
                "type": "status"
              }
            }
          }
        }, {
          "id": "10806",
          "type": "order_platform",
          "attributes": {
            "id": 10806,
            "name": "Grubhub"
          },
          "relationships": {
            "partner": {
              "data": {
                "id": "4324",
                "type": "partner"
              }
            },
            "menu_mapping_menu": {
              "data": null
            },
            "menu_publish_status": {
              "data": {
                "id": "1E89188D-025B-4C86-ADC3-2F92E2296F75",
                "type": "status"
              }
            }
          }
        }, {
          "id": "10807",
          "type": "order_platform",
          "attributes": {
            "id": 10807,
            "name": "DoorDash"
          },
          "relationships": {
            "partner": {
              "data": {
                "id": "4324",
                "type": "partner"
              }
            },
            "menu_mapping_menu": {
              "data": null
            },
            "menu_publish_status": {
              "data": {
                "id": "4027CFFB-1E5F-4F0A-962E-5B365EF88464",
                "type": "status"
              }
            }
          }
        }, {
          "id": "10808",
          "type": "order_platform",
          "attributes": {
            "id": 10808,
            "name": "Caviar"
          },
          "relationships": {
            "partner": {
              "data": {
                "id": "4324",
                "type": "partner"
              }
            },
            "menu_mapping_menu": {
              "data": null
            },
            "menu_publish_status": {
              "data": {
                "id": "863CEDB9-38FA-4DB1-85C8-29E17E4C654B",
                "type": "status"
              }
            }
          }
        }, {
          "id": "12215",
          "type": "order_platform",
          "attributes": {
            "id": 12215,
            "name": "Delivery.com"
          },
          "relationships": {
            "partner": {
              "data": {
                "id": "4324",
                "type": "partner"
              }
            },
            "menu_mapping_menu": {
              "data": null
            },
            "menu_publish_status": {
              "data": {
                "id": "A641A5E8-7B28-441D-9A3A-968279C6736F",
                "type": "status"
              }
            }
          }
        }, {
          "id": "12216",
          "type": "order_platform",
          "attributes": {
            "id": 12216,
            "name": "Garcon"
          },
          "relationships": {
            "partner": {
              "data": {
                "id": "4324",
                "type": "partner"
              }
            },
            "menu_mapping_menu": {
              "data": null
            },
            "menu_publish_status": {
              "data": {
                "id": "40BD25E6-47A8-4A6C-81FD-48212B754872",
                "type": "status"
              }
            }
          }
        }, {
          "id": "14796",
          "type": "order_platform",
          "attributes": {
            "id": 14796,
            "name": "Postmates"
          },
          "relationships": {
            "partner": {
              "data": {
                "id": "4324",
                "type": "partner"
              }
            },
            "menu_mapping_menu": {
              "data": null
            },
            "menu_publish_status": {
              "data": {
                "id": "7011DC55-7F5B-406B-BD15-708CE7124ECD",
                "type": "status"
              }
            }
          }
        }, {
          "id": "AAA4AF56-9123-4F85-9809-A98CF55A0733",
          "type": "status",
          "attributes": {
            "success": true,
            "timestamp": "2020-06-11T14:04:25.529-05:00",
            "category": "menu_publish"
          }
        }, {
          "id": "1E89188D-025B-4C86-ADC3-2F92E2296F75",
          "type": "status",
          "attributes": {
            "success": true,
            "timestamp": "2020-06-11T14:04:25.569-05:00",
            "category": "menu_publish"
          }
        }, {
          "id": "4027CFFB-1E5F-4F0A-962E-5B365EF88464",
          "type": "status",
          "attributes": {
            "success": true,
            "timestamp": "2020-06-11T14:04:25.579-05:00",
            "category": "menu_publish"
          }
        }, {
          "id": "863CEDB9-38FA-4DB1-85C8-29E17E4C654B",
          "type": "status",
          "attributes": {
            "success": true,
            "timestamp": "2020-06-11T14:04:25.619-05:00",
            "category": "menu_publish"
          }
        }, {
          "id": "A641A5E8-7B28-441D-9A3A-968279C6736F",
          "type": "status",
          "attributes": {
            "success": true,
            "timestamp": "2020-06-11T14:04:26.077-05:00",
            "category": "menu_publish"
          }
        }, {
          "id": "40BD25E6-47A8-4A6C-81FD-48212B754872",
          "type": "status",
          "attributes": {
            "success": true,
            "timestamp": "2020-06-11T14:04:26.087-05:00",
            "category": "menu_publish"
          }
        }, {
          "id": "7011DC55-7F5B-406B-BD15-708CE7124ECD",
          "type": "status",
          "attributes": {
            "success": true,
            "timestamp": "2020-06-11T14:04:26.466-05:00",
            "category": "menu_publish"
          }
        }, {
          "id": "ABDDF724-9453-4ABA-985E-B40A50AB5099",
          "type": "status",
          "attributes": {
            "success": true,
            "timestamp": "2020-06-11T14:04:24.163-05:00",
            "category": "menu_import"
          }
        }, {
          "id": "10815",
          "type": "order_platform",
          "attributes": {
            "id": 10815,
            "name": "Grubhub"
          },
          "relationships": {
            "partner": {
              "data": {
                "id": "4326",
                "type": "partner"
              }
            },
            "menu_mapping_menu": {
              "data": null
            },
            "menu_publish_status": {
              "data": {
                "id": "47133309-69B7-428B-B846-AE91CA0F4353",
                "type": "status"
              }
            }
          }
        }, {
          "id": "10817",
          "type": "order_platform",
          "attributes": {
            "id": 10817,
            "name": "DoorDash"
          },
          "relationships": {
            "partner": {
              "data": {
                "id": "4326",
                "type": "partner"
              }
            },
            "menu_mapping_menu": {
              "data": null
            },
            "menu_publish_status": {
              "data": {
                "id": "47E46B71-3C32-4A36-A740-443F90851263",
                "type": "status"
              }
            }
          }
        }, {
          "id": "10818",
          "type": "order_platform",
          "attributes": {
            "id": 10818,
            "name": "UberEats"
          },
          "relationships": {
            "partner": {
              "data": {
                "id": "4326",
                "type": "partner"
              }
            },
            "menu_mapping_menu": {
              "data": null
            },
            "menu_publish_status": {
              "data": {
                "id": "5DCCA21A-7B02-4EF7-AF97-DDED086214B6",
                "type": "status"
              }
            }
          }
        }, {
          "id": "14794",
          "type": "order_platform",
          "attributes": {
            "id": 14794,
            "name": "Postmates"
          },
          "relationships": {
            "partner": {
              "data": {
                "id": "4326",
                "type": "partner"
              }
            },
            "menu_mapping_menu": {
              "data": null
            },
            "menu_publish_status": {
              "data": {
                "id": "CF0A69A9-EAC3-45FC-AC57-AB005DE0E42B",
                "type": "status"
              }
            }
          }
        }, {
          "id": "47133309-69B7-428B-B846-AE91CA0F4353",
          "type": "status",
          "attributes": {
            "success": true,
            "timestamp": "2020-06-11T14:04:25.629-05:00",
            "category": "menu_publish"
          }
        }, {
          "id": "47E46B71-3C32-4A36-A740-443F90851263",
          "type": "status",
          "attributes": {
            "success": true,
            "timestamp": "2020-06-11T14:04:25.673-05:00",
            "category": "menu_publish"
          }
        }, {
          "id": "5DCCA21A-7B02-4EF7-AF97-DDED086214B6",
          "type": "status",
          "attributes": {
            "success": true,
            "timestamp": "2020-06-11T14:04:25.679-05:00",
            "category": "menu_publish"
          }
        }, {
          "id": "CF0A69A9-EAC3-45FC-AC57-AB005DE0E42B",
          "type": "status",
          "attributes": {
            "success": true,
            "timestamp": "2020-06-11T14:04:26.456-05:00",
            "category": "menu_publish"
          }
        }, {
          "id": "1AF387AF-B5CC-4D3D-8A56-BCF216786FA1",
          "type": "status",
          "attributes": {
            "success": true,
            "timestamp": "2020-06-11T14:04:24.173-05:00",
            "category": "menu_import"
          }
        }, {
          "id": "10819",
          "type": "order_platform",
          "attributes": {
            "id": 10819,
            "name": "Grubhub"
          },
          "relationships": {
            "partner": {
              "data": {
                "id": "4327",
                "type": "partner"
              }
            },
            "menu_mapping_menu": {
              "data": null
            },
            "menu_publish_status": {
              "data": {
                "id": "E2F015A0-65F4-4564-B007-475C6179D5A9",
                "type": "status"
              }
            }
          }
        }, {
          "id": "10820",
          "type": "order_platform",
          "attributes": {
            "id": 10820,
            "name": "DoorDash"
          },
          "relationships": {
            "partner": {
              "data": {
                "id": "4327",
                "type": "partner"
              }
            },
            "menu_mapping_menu": {
              "data": null
            },
            "menu_publish_status": {
              "data": {
                "id": "E0475227-E687-42AA-BB70-DA417C8EAD69",
                "type": "status"
              }
            }
          }
        }, {
          "id": "10821",
          "type": "order_platform",
          "attributes": {
            "id": 10821,
            "name": "UberEats"
          },
          "relationships": {
            "partner": {
              "data": {
                "id": "4327",
                "type": "partner"
              }
            },
            "menu_mapping_menu": {
              "data": null
            },
            "menu_publish_status": {
              "data": {
                "id": "DFE0F645-9FB6-4C17-8297-708AC7D032F0",
                "type": "status"
              }
            }
          }
        }, {
          "id": "12819",
          "type": "order_platform",
          "attributes": {
            "id": 12819,
            "name": "Garcon"
          },
          "relationships": {
            "partner": {
              "data": {
                "id": "4327",
                "type": "partner"
              }
            },
            "menu_mapping_menu": {
              "data": null
            },
            "menu_publish_status": {
              "data": {
                "id": "E0E81C36-91F3-4994-BF1F-7A8108F25907",
                "type": "status"
              }
            }
          }
        }, {
          "id": "15001",
          "type": "order_platform",
          "attributes": {
            "id": 15001,
            "name": "Postmates"
          },
          "relationships": {
            "partner": {
              "data": {
                "id": "4327",
                "type": "partner"
              }
            },
            "menu_mapping_menu": {
              "data": null
            },
            "menu_publish_status": {
              "data": {
                "id": "46545187-BD8C-4DDC-A913-295DF53E33AD",
                "type": "status"
              }
            }
          }
        }, {
          "id": "E2F015A0-65F4-4564-B007-475C6179D5A9",
          "type": "status",
          "attributes": {
            "success": true,
            "timestamp": "2020-06-11T14:04:25.719-05:00",
            "category": "menu_publish"
          }
        }, {
          "id": "E0475227-E687-42AA-BB70-DA417C8EAD69",
          "type": "status",
          "attributes": {
            "success": true,
            "timestamp": "2020-06-11T14:04:25.729-05:00",
            "category": "menu_publish"
          }
        }, {
          "id": "DFE0F645-9FB6-4C17-8297-708AC7D032F0",
          "type": "status",
          "attributes": {
            "success": true,
            "timestamp": "2020-06-11T14:04:25.769-05:00",
            "category": "menu_publish"
          }
        }, {
          "id": "E0E81C36-91F3-4994-BF1F-7A8108F25907",
          "type": "status",
          "attributes": {
            "success": true,
            "timestamp": "2020-06-11T14:04:26.260-05:00",
            "category": "menu_publish"
          }
        }, {
          "id": "46545187-BD8C-4DDC-A913-295DF53E33AD",
          "type": "status",
          "attributes": {
            "success": true,
            "timestamp": "2020-06-11T14:04:26.503-05:00",
            "category": "menu_publish"
          }
        }, {
          "id": "2C308F7C-0DAF-4DE1-8B69-BDB9FB6AFEB8",
          "type": "status",
          "attributes": {
            "success": true,
            "timestamp": "2020-06-11T14:04:24.213-05:00",
            "category": "menu_import"
          }
        }, {
          "id": "11574",
          "type": "order_platform",
          "attributes": {
            "id": 11574,
            "name": "DoorDash"
          },
          "relationships": {
            "partner": {
              "data": {
                "id": "4696",
                "type": "partner"
              }
            },
            "menu_mapping_menu": {
              "data": null
            },
            "menu_publish_status": {
              "data": {
                "id": "3013B7D9-7FFF-4504-99E1-87CC3FB657D0",
                "type": "status"
              }
            }
          }
        }, {
          "id": "12167",
          "type": "order_platform",
          "attributes": {
            "id": 12167,
            "name": "ezCater"
          },
          "relationships": {
            "partner": {
              "data": {
                "id": "4696",
                "type": "partner"
              }
            },
            "menu_mapping_menu": {
              "data": null
            },
            "menu_publish_status": {
              "data": {
                "id": "5C084CF9-127B-4275-B6F0-456FA37510A0",
                "type": "status"
              }
            }
          }
        }, {
          "id": "21955",
          "type": "order_platform",
          "attributes": {
            "id": 21955,
            "name": "UberEats"
          },
          "relationships": {
            "partner": {
              "data": {
                "id": "4696",
                "type": "partner"
              }
            },
            "menu_mapping_menu": {
              "data": null
            },
            "menu_publish_status": {
              "data": {
                "id": "C94D24B2-9C33-44B0-99C7-6DDC000FADDE",
                "type": "status"
              }
            }
          }
        }, {
          "id": "3013B7D9-7FFF-4504-99E1-87CC3FB657D0",
          "type": "status",
          "attributes": {
            "success": true,
            "timestamp": "2020-06-11T14:04:25.862-05:00",
            "category": "menu_publish"
          }
        }, {
          "id": "5C084CF9-127B-4275-B6F0-456FA37510A0",
          "type": "status",
          "attributes": {
            "success": true,
            "timestamp": "2020-06-11T14:04:25.976-05:00",
            "category": "menu_publish"
          }
        }, {
          "id": "C94D24B2-9C33-44B0-99C7-6DDC000FADDE",
          "type": "status",
          "attributes": {
            "success": true,
            "timestamp": "2020-06-11T14:04:26.763-05:00",
            "category": "menu_publish"
          }
        }, {
          "id": "B1AB98F3-16B4-4D1C-8A8B-F46DFB012AB1",
          "type": "status",
          "attributes": {
            "success": true,
            "timestamp": "2020-06-11T14:04:24.223-05:00",
            "category": "menu_import"
          }
        }, {
          "id": "12155",
          "type": "order_platform",
          "attributes": {
            "id": 12155,
            "name": "DoorDash"
          },
          "relationships": {
            "partner": {
              "data": {
                "id": "4698",
                "type": "partner"
              }
            },
            "menu_mapping_menu": {
              "data": null
            },
            "menu_publish_status": {
              "data": {
                "id": "01312AC4-FC1F-43F5-9F69-667E54AC948A",
                "type": "status"
              }
            }
          }
        }, {
          "id": "12387",
          "type": "order_platform",
          "attributes": {
            "id": 12387,
            "name": "Garcon"
          },
          "relationships": {
            "partner": {
              "data": {
                "id": "4698",
                "type": "partner"
              }
            },
            "menu_mapping_menu": {
              "data": null
            },
            "menu_publish_status": {
              "data": {
                "id": "B9B9CB29-E9D1-4007-A465-331C85B01796",
                "type": "status"
              }
            }
          }
        }, {
          "id": "01312AC4-FC1F-43F5-9F69-667E54AC948A",
          "type": "status",
          "attributes": {
            "success": true,
            "timestamp": "2020-06-11T14:04:25.912-05:00",
            "category": "menu_publish"
          }
        }, {
          "id": "B9B9CB29-E9D1-4007-A465-331C85B01796",
          "type": "status",
          "attributes": {
            "success": true,
            "timestamp": "2020-06-11T14:04:26.097-05:00",
            "category": "menu_publish"
          }
        }, {
          "id": "35A3A035-ABAE-4BDE-96BF-9B332BB63C0B",
          "type": "status",
          "attributes": {
            "success": true,
            "timestamp": "2020-06-11T14:04:24.263-05:00",
            "category": "menu_import"
          }
        }, {
          "id": "12156",
          "type": "order_platform",
          "attributes": {
            "id": 12156,
            "name": "DoorDash"
          },
          "relationships": {
            "partner": {
              "data": {
                "id": "4699",
                "type": "partner"
              }
            },
            "menu_mapping_menu": {
              "data": null
            },
            "menu_publish_status": {
              "data": {
                "id": "B695BFE5-6B24-4CF9-A304-93CAE6BA57DA",
                "type": "status"
              }
            }
          }
        }, {
          "id": "B695BFE5-6B24-4CF9-A304-93CAE6BA57DA",
          "type": "status",
          "attributes": {
            "success": true,
            "timestamp": "2020-06-11T14:04:25.926-05:00",
            "category": "menu_publish"
          }
        }, {
          "id": "EDAB0DCF-DF17-499B-91A9-7ED62A110273",
          "type": "status",
          "attributes": {
            "success": true,
            "timestamp": "2020-06-11T14:04:24.276-05:00",
            "category": "menu_import"
          }
        }, {
          "id": "12145",
          "type": "order_platform",
          "attributes": {
            "id": 12145,
            "name": "DoorDash"
          },
          "relationships": {
            "partner": {
              "data": {
                "id": "4759",
                "type": "partner"
              }
            },
            "menu_mapping_menu": {
              "data": null
            },
            "menu_publish_status": {
              "data": {
                "id": "6F73EA63-6E66-441E-9A2A-926CD71B0F23",
                "type": "status"
              }
            }
          }
        }, {
          "id": "12441",
          "type": "order_platform",
          "attributes": {
            "id": 12441,
            "name": "Garcon"
          },
          "relationships": {
            "partner": {
              "data": {
                "id": "4759",
                "type": "partner"
              }
            },
            "menu_mapping_menu": {
              "data": null
            },
            "menu_publish_status": {
              "data": {
                "id": "4DC08DDC-1D6F-49EC-B942-1CE08C8A48D4",
                "type": "status"
              }
            }
          }
        }, {
          "id": "6F73EA63-6E66-441E-9A2A-926CD71B0F23",
          "type": "status",
          "attributes": {
            "success": true,
            "timestamp": "2020-06-11T14:04:25.876-05:00",
            "category": "menu_publish"
          }
        }, {
          "id": "4DC08DDC-1D6F-49EC-B942-1CE08C8A48D4",
          "type": "status",
          "attributes": {
            "success": true,
            "timestamp": "2020-06-11T14:04:26.113-05:00",
            "category": "menu_publish"
          }
        }, {
          "id": "585DF2A8-31A5-40C4-B908-621361633F5F",
          "type": "status",
          "attributes": {
            "success": true,
            "timestamp": "2020-06-11T14:04:24.320-05:00",
            "category": "menu_import"
          }
        }, {
          "id": "12157",
          "type": "order_platform",
          "attributes": {
            "id": 12157,
            "name": "DoorDash"
          },
          "relationships": {
            "partner": {
              "data": {
                "id": "4938",
                "type": "partner"
              }
            },
            "menu_mapping_menu": {
              "data": null
            },
            "menu_publish_status": {
              "data": {
                "id": "2BED8FF1-5588-47E1-9753-87C7FDA9204E",
                "type": "status"
              }
            }
          }
        }, {
          "id": "12674",
          "type": "order_platform",
          "attributes": {
            "id": 12674,
            "name": "Garcon"
          },
          "relationships": {
            "partner": {
              "data": {
                "id": "4938",
                "type": "partner"
              }
            },
            "menu_mapping_menu": {
              "data": null
            },
            "menu_publish_status": {
              "data": {
                "id": "2DDA96C7-54AE-4F15-BC85-3CAE8FA49350",
                "type": "status"
              }
            }
          }
        }, {
          "id": "21959",
          "type": "order_platform",
          "attributes": {
            "id": 21959,
            "name": "UberEats"
          },
          "relationships": {
            "partner": {
              "data": {
                "id": "4938",
                "type": "partner"
              }
            },
            "menu_mapping_menu": {
              "data": null
            },
            "menu_publish_status": {
              "data": {
                "id": "AFFE954D-9B23-4930-A5B4-C3122F4F6055",
                "type": "status"
              }
            }
          }
        }, {
          "id": "2BED8FF1-5588-47E1-9753-87C7FDA9204E",
          "type": "status",
          "attributes": {
            "success": true,
            "timestamp": "2020-06-11T14:04:25.962-05:00",
            "category": "menu_publish"
          }
        }, {
          "id": "2DDA96C7-54AE-4F15-BC85-3CAE8FA49350",
          "type": "status",
          "attributes": {
            "success": true,
            "timestamp": "2020-06-11T14:04:26.130-05:00",
            "category": "menu_publish"
          }
        }, {
          "id": "AFFE954D-9B23-4930-A5B4-C3122F4F6055",
          "type": "status",
          "attributes": {
            "success": true,
            "timestamp": "2020-06-11T14:04:26.769-05:00",
            "category": "menu_publish"
          }
        }, {
          "id": "8B5EB0E5-F432-499A-AC5A-32E0A694B764",
          "type": "status",
          "attributes": {
            "success": true,
            "timestamp": "2020-06-11T14:04:24.363-05:00",
            "category": "menu_import"
          }
        }, {
          "id": "12181",
          "type": "order_platform",
          "attributes": {
            "id": 12181,
            "name": "DoorDash"
          },
          "relationships": {
            "partner": {
              "data": {
                "id": "4939",
                "type": "partner"
              }
            },
            "menu_mapping_menu": {
              "data": null
            },
            "menu_publish_status": {
              "data": {
                "id": "EBA7B482-5146-48F6-9F13-5EDE8AF4C898",
                "type": "status"
              }
            }
          }
        }, {
          "id": "12425",
          "type": "order_platform",
          "attributes": {
            "id": 12425,
            "name": "Garcon"
          },
          "relationships": {
            "partner": {
              "data": {
                "id": "4939",
                "type": "partner"
              }
            },
            "menu_mapping_menu": {
              "data": null
            },
            "menu_publish_status": {
              "data": {
                "id": "C01D999F-333A-4B06-8531-D72BD60179E3",
                "type": "status"
              }
            }
          }
        }, {
          "id": "EBA7B482-5146-48F6-9F13-5EDE8AF4C898",
          "type": "status",
          "attributes": {
            "success": true,
            "timestamp": "2020-06-11T14:04:26.013-05:00",
            "category": "menu_publish"
          }
        }, {
          "id": "C01D999F-333A-4B06-8531-D72BD60179E3",
          "type": "status",
          "attributes": {
            "success": true,
            "timestamp": "2020-06-11T14:04:26.103-05:00",
            "category": "menu_publish"
          }
        }, {
          "id": "6A0C02C3-B097-4744-93FF-0CA08800FCA8",
          "type": "status",
          "attributes": {
            "success": true,
            "timestamp": "2020-06-11T14:04:24.376-05:00",
            "category": "menu_import"
          }
        }, {
          "id": "12196",
          "type": "order_platform",
          "attributes": {
            "id": 12196,
            "name": "DoorDash"
          },
          "relationships": {
            "partner": {
              "data": {
                "id": "4940",
                "type": "partner"
              }
            },
            "menu_mapping_menu": {
              "data": null
            },
            "menu_publish_status": {
              "data": {
                "id": "20D9E5A7-B2AB-4764-9EC8-28F8A196AA72",
                "type": "status"
              }
            }
          }
        }, {
          "id": "12443",
          "type": "order_platform",
          "attributes": {
            "id": 12443,
            "name": "Garcon"
          },
          "relationships": {
            "partner": {
              "data": {
                "id": "4940",
                "type": "partner"
              }
            },
            "menu_mapping_menu": {
              "data": null
            },
            "menu_publish_status": {
              "data": {
                "id": "90B369D2-9071-4AD5-8EE0-6631D1C67615",
                "type": "status"
              }
            }
          }
        }, {
          "id": "20D9E5A7-B2AB-4764-9EC8-28F8A196AA72",
          "type": "status",
          "attributes": {
            "success": true,
            "timestamp": "2020-06-11T14:04:26.063-05:00",
            "category": "menu_publish"
          }
        }, {
          "id": "90B369D2-9071-4AD5-8EE0-6631D1C67615",
          "type": "status",
          "attributes": {
            "success": true,
            "timestamp": "2020-06-11T14:04:26.123-05:00",
            "category": "menu_publish"
          }
        }, {
          "id": "57A0247C-C848-49E0-B709-91D5F3CA9C59",
          "type": "status",
          "attributes": {
            "success": true,
            "timestamp": "2020-06-11T14:04:24.413-05:00",
            "category": "menu_import"
          }
        }, {
          "id": "12194",
          "type": "order_platform",
          "attributes": {
            "id": 12194,
            "name": "DoorDash"
          },
          "relationships": {
            "partner": {
              "data": {
                "id": "4941",
                "type": "partner"
              }
            },
            "menu_mapping_menu": {
              "data": null
            },
            "menu_publish_status": {
              "data": {
                "id": "C82646C2-DC25-4559-88A8-7FA992647DB4",
                "type": "status"
              }
            }
          }
        }, {
          "id": "C82646C2-DC25-4559-88A8-7FA992647DB4",
          "type": "status",
          "attributes": {
            "success": true,
            "timestamp": "2020-06-11T14:04:26.023-05:00",
            "category": "menu_publish"
          }
        }, {
          "id": "5DD78286-DC81-41F8-A22B-6DC09C61AC97",
          "type": "status",
          "attributes": {
            "success": true,
            "timestamp": "2020-06-11T14:04:24.423-05:00",
            "category": "menu_import"
          }
        }, {
          "id": "12682",
          "type": "order_platform",
          "attributes": {
            "id": 12682,
            "name": "DoorDash"
          },
          "relationships": {
            "partner": {
              "data": {
                "id": "5153",
                "type": "partner"
              }
            },
            "menu_mapping_menu": {
              "data": null
            },
            "menu_publish_status": {
              "data": {
                "id": "3BD77AFC-CB80-4114-A289-665A1B07D939",
                "type": "status"
              }
            }
          }
        }, {
          "id": "21960",
          "type": "order_platform",
          "attributes": {
            "id": 21960,
            "name": "UberEats"
          },
          "relationships": {
            "partner": {
              "data": {
                "id": "5153",
                "type": "partner"
              }
            },
            "menu_mapping_menu": {
              "data": null
            },
            "menu_publish_status": {
              "data": {
                "id": "676FB355-F889-47E6-9B17-C1CF99C54E8D",
                "type": "status"
              }
            }
          }
        }, {
          "id": "3BD77AFC-CB80-4114-A289-665A1B07D939",
          "type": "status",
          "attributes": {
            "success": true,
            "timestamp": "2020-06-11T14:04:26.140-05:00",
            "category": "menu_publish"
          }
        }, {
          "id": "676FB355-F889-47E6-9B17-C1CF99C54E8D",
          "type": "status",
          "attributes": {
            "success": true,
            "timestamp": "2020-06-11T14:04:26.779-05:00",
            "category": "menu_publish"
          }
        }, {
          "id": "31C8F7D6-D228-4410-8F1C-070FEBB291E7",
          "type": "status",
          "attributes": {
            "success": true,
            "timestamp": "2020-06-11T14:04:24.463-05:00",
            "category": "menu_import"
          }
        }, {
          "id": "12701",
          "type": "order_platform",
          "attributes": {
            "id": 12701,
            "name": "UberEats"
          },
          "relationships": {
            "partner": {
              "data": {
                "id": "5162",
                "type": "partner"
              }
            },
            "menu_mapping_menu": {
              "data": null
            },
            "menu_publish_status": {
              "data": {
                "id": "89B7F30A-A71C-4B65-A372-3BAC94E98563",
                "type": "status"
              }
            }
          }
        }, {
          "id": "12776",
          "type": "order_platform",
          "attributes": {
            "id": 12776,
            "name": "Grubhub"
          },
          "relationships": {
            "partner": {
              "data": {
                "id": "5162",
                "type": "partner"
              }
            },
            "menu_mapping_menu": {
              "data": null
            },
            "menu_publish_status": {
              "data": {
                "id": "0BCDF346-D385-434F-A27A-98E34767EFE6",
                "type": "status"
              }
            }
          }
        }, {
          "id": "12777",
          "type": "order_platform",
          "attributes": {
            "id": 12777,
            "name": "Postmates"
          },
          "relationships": {
            "partner": {
              "data": {
                "id": "5162",
                "type": "partner"
              }
            },
            "menu_mapping_menu": {
              "data": null
            },
            "menu_publish_status": {
              "data": {
                "id": "E6473B8A-9290-4996-9D0C-724FD9A10775",
                "type": "status"
              }
            }
          }
        }, {
          "id": "12778",
          "type": "order_platform",
          "attributes": {
            "id": 12778,
            "name": "Chownow"
          },
          "relationships": {
            "partner": {
              "data": {
                "id": "5162",
                "type": "partner"
              }
            },
            "menu_mapping_menu": {
              "data": null
            },
            "menu_publish_status": {
              "data": {
                "id": "3C3EE659-9B45-4C90-9A4B-D2DD913DBDCB",
                "type": "status"
              }
            }
          }
        }, {
          "id": "12779",
          "type": "order_platform",
          "attributes": {
            "id": 12779,
            "name": "Ritual"
          },
          "relationships": {
            "partner": {
              "data": {
                "id": "5162",
                "type": "partner"
              }
            },
            "menu_mapping_menu": {
              "data": null
            },
            "menu_publish_status": {
              "data": {
                "id": "66195DBC-978E-47CE-96AE-6B320D55B98F",
                "type": "status"
              }
            }
          }
        }, {
          "id": "12781",
          "type": "order_platform",
          "attributes": {
            "id": 12781,
            "name": "Caviar"
          },
          "relationships": {
            "partner": {
              "data": {
                "id": "5162",
                "type": "partner"
              }
            },
            "menu_mapping_menu": {
              "data": null
            },
            "menu_publish_status": {
              "data": {
                "id": "71805208-C80A-4FD2-B477-003182615D17",
                "type": "status"
              }
            }
          }
        }, {
          "id": "89B7F30A-A71C-4B65-A372-3BAC94E98563",
          "type": "status",
          "attributes": {
            "success": true,
            "timestamp": "2020-06-11T14:04:26.153-05:00",
            "category": "menu_publish"
          }
        }, {
          "id": "0BCDF346-D385-434F-A27A-98E34767EFE6",
          "type": "status",
          "attributes": {
            "success": true,
            "timestamp": "2020-06-11T14:04:26.216-05:00",
            "category": "menu_publish"
          }
        }, {
          "id": "E6473B8A-9290-4996-9D0C-724FD9A10775",
          "type": "status",
          "attributes": {
            "success": true,
            "timestamp": "2020-06-11T14:04:26.226-05:00",
            "category": "menu_publish"
          }
        }, {
          "id": "3C3EE659-9B45-4C90-9A4B-D2DD913DBDCB",
          "type": "status",
          "attributes": {
            "success": true,
            "timestamp": "2020-06-11T14:04:26.233-05:00",
            "category": "menu_publish"
          }
        }, {
          "id": "66195DBC-978E-47CE-96AE-6B320D55B98F",
          "type": "status",
          "attributes": {
            "success": true,
            "timestamp": "2020-06-11T14:04:26.243-05:00",
            "category": "menu_publish"
          }
        }, {
          "id": "71805208-C80A-4FD2-B477-003182615D17",
          "type": "status",
          "attributes": {
            "success": true,
            "timestamp": "2020-06-11T14:04:26.250-05:00",
            "category": "menu_publish"
          }
        }, {
          "id": "C6C74050-549C-47BB-AB86-CA0EA30F28C6",
          "type": "status",
          "attributes": {
            "success": true,
            "timestamp": "2020-06-11T14:04:24.473-05:00",
            "category": "menu_import"
          }
        }, {
          "id": "12702",
          "type": "order_platform",
          "attributes": {
            "id": 12702,
            "name": "UberEats"
          },
          "relationships": {
            "partner": {
              "data": {
                "id": "5165",
                "type": "partner"
              }
            },
            "menu_mapping_menu": {
              "data": null
            },
            "menu_publish_status": {
              "data": {
                "id": "B0D4336E-33F7-4DB4-B78D-F9D604018FFF",
                "type": "status"
              }
            }
          }
        }, {
          "id": "12718",
          "type": "order_platform",
          "attributes": {
            "id": 12718,
            "name": "Grubhub"
          },
          "relationships": {
            "partner": {
              "data": {
                "id": "5165",
                "type": "partner"
              }
            },
            "menu_mapping_menu": {
              "data": null
            },
            "menu_publish_status": {
              "data": {
                "id": "2110E9B2-BBBC-4C7D-B00F-EFF0553A2DD5",
                "type": "status"
              }
            }
          }
        }, {
          "id": "12724",
          "type": "order_platform",
          "attributes": {
            "id": 12724,
            "name": "Postmates"
          },
          "relationships": {
            "partner": {
              "data": {
                "id": "5165",
                "type": "partner"
              }
            },
            "menu_mapping_menu": {
              "data": null
            },
            "menu_publish_status": {
              "data": {
                "id": "4F491275-7E1A-4261-8F9A-B57C2790B071",
                "type": "status"
              }
            }
          }
        }, {
          "id": "12725",
          "type": "order_platform",
          "attributes": {
            "id": 12725,
            "name": "Chownow"
          },
          "relationships": {
            "partner": {
              "data": {
                "id": "5165",
                "type": "partner"
              }
            },
            "menu_mapping_menu": {
              "data": null
            },
            "menu_publish_status": {
              "data": {
                "id": "B23EA8CF-BD3A-4BF3-A3F0-C36D93DFBA1F",
                "type": "status"
              }
            }
          }
        }, {
          "id": "12726",
          "type": "order_platform",
          "attributes": {
            "id": 12726,
            "name": "Ritual"
          },
          "relationships": {
            "partner": {
              "data": {
                "id": "5165",
                "type": "partner"
              }
            },
            "menu_mapping_menu": {
              "data": null
            },
            "menu_publish_status": {
              "data": {
                "id": "4845AE4E-B555-46E2-8871-353A93A77656",
                "type": "status"
              }
            }
          }
        }, {
          "id": "12727",
          "type": "order_platform",
          "attributes": {
            "id": 12727,
            "name": "Caviar"
          },
          "relationships": {
            "partner": {
              "data": {
                "id": "5165",
                "type": "partner"
              }
            },
            "menu_mapping_menu": {
              "data": null
            },
            "menu_publish_status": {
              "data": {
                "id": "BAA155D3-BEE4-462B-9D29-27AE6414A117",
                "type": "status"
              }
            }
          }
        }, {
          "id": "B0D4336E-33F7-4DB4-B78D-F9D604018FFF",
          "type": "status",
          "attributes": {
            "success": true,
            "timestamp": "2020-06-11T14:04:26.163-05:00",
            "category": "menu_publish"
          }
        }, {
          "id": "2110E9B2-BBBC-4C7D-B00F-EFF0553A2DD5",
          "type": "status",
          "attributes": {
            "success": true,
            "timestamp": "2020-06-11T14:04:26.170-05:00",
            "category": "menu_publish"
          }
        }, {
          "id": "4F491275-7E1A-4261-8F9A-B57C2790B071",
          "type": "status",
          "attributes": {
            "success": true,
            "timestamp": "2020-06-11T14:04:26.180-05:00",
            "category": "menu_publish"
          }
        }, {
          "id": "B23EA8CF-BD3A-4BF3-A3F0-C36D93DFBA1F",
          "type": "status",
          "attributes": {
            "success": true,
            "timestamp": "2020-06-11T14:04:26.186-05:00",
            "category": "menu_publish"
          }
        }, {
          "id": "4845AE4E-B555-46E2-8871-353A93A77656",
          "type": "status",
          "attributes": {
            "success": true,
            "timestamp": "2020-06-11T14:04:26.196-05:00",
            "category": "menu_publish"
          }
        }, {
          "id": "BAA155D3-BEE4-462B-9D29-27AE6414A117",
          "type": "status",
          "attributes": {
            "success": true,
            "timestamp": "2020-06-11T14:04:26.206-05:00",
            "category": "menu_publish"
          }
        }, {
          "id": "344D5D57-C0C7-4C2D-BDCD-20207711BF17",
          "type": "status",
          "attributes": {
            "success": true,
            "timestamp": "2020-06-11T14:04:24.513-05:00",
            "category": "menu_import"
          }
        }, {
          "id": "12869",
          "type": "order_platform",
          "attributes": {
            "id": 12869,
            "name": "Grubhub"
          },
          "relationships": {
            "partner": {
              "data": {
                "id": "5212",
                "type": "partner"
              }
            },
            "menu_mapping_menu": {
              "data": null
            },
            "menu_publish_status": {
              "data": {
                "id": "F835A640-FE0D-46BB-ACFB-543F9CD313EC",
                "type": "status"
              }
            }
          }
        }, {
          "id": "12870",
          "type": "order_platform",
          "attributes": {
            "id": 12870,
            "name": "DoorDash"
          },
          "relationships": {
            "partner": {
              "data": {
                "id": "5212",
                "type": "partner"
              }
            },
            "menu_mapping_menu": {
              "data": null
            },
            "menu_publish_status": {
              "data": {
                "id": "8B6DA382-A233-424B-BBD2-C5DC3E39D5CD",
                "type": "status"
              }
            }
          }
        }, {
          "id": "12872",
          "type": "order_platform",
          "attributes": {
            "id": 12872,
            "name": "Caviar"
          },
          "relationships": {
            "partner": {
              "data": {
                "id": "5212",
                "type": "partner"
              }
            },
            "menu_mapping_menu": {
              "data": null
            },
            "menu_publish_status": {
              "data": {
                "id": "590ABC67-8DD5-41E5-B3C8-A244612C32AD",
                "type": "status"
              }
            }
          }
        }, {
          "id": "12873",
          "type": "order_platform",
          "attributes": {
            "id": 12873,
            "name": "UberEats"
          },
          "relationships": {
            "partner": {
              "data": {
                "id": "5212",
                "type": "partner"
              }
            },
            "menu_mapping_menu": {
              "data": null
            },
            "menu_publish_status": {
              "data": {
                "id": "00ECF20C-06D6-44AD-B478-DD4364B1A4F6",
                "type": "status"
              }
            }
          }
        }, {
          "id": "13207",
          "type": "order_platform",
          "attributes": {
            "id": 13207,
            "name": "Garcon"
          },
          "relationships": {
            "partner": {
              "data": {
                "id": "5212",
                "type": "partner"
              }
            },
            "menu_mapping_menu": {
              "data": null
            },
            "menu_publish_status": {
              "data": {
                "id": "D87EB3DD-98EF-4CA1-97DD-0CF7CEA97227",
                "type": "status"
              }
            }
          }
        }, {
          "id": "15000",
          "type": "order_platform",
          "attributes": {
            "id": 15000,
            "name": "Postmates"
          },
          "relationships": {
            "partner": {
              "data": {
                "id": "5212",
                "type": "partner"
              }
            },
            "menu_mapping_menu": {
              "data": null
            },
            "menu_publish_status": {
              "data": {
                "id": "CAB9C6EB-CC1C-47E6-A7A5-10B53E5ADD01",
                "type": "status"
              }
            }
          }
        }, {
          "id": "F835A640-FE0D-46BB-ACFB-543F9CD313EC",
          "type": "status",
          "attributes": {
            "success": true,
            "timestamp": "2020-06-11T14:04:26.266-05:00",
            "category": "menu_publish"
          }
        }, {
          "id": "8B6DA382-A233-424B-BBD2-C5DC3E39D5CD",
          "type": "status",
          "attributes": {
            "success": true,
            "timestamp": "2020-06-11T14:04:26.280-05:00",
            "category": "menu_publish"
          }
        }, {
          "id": "590ABC67-8DD5-41E5-B3C8-A244612C32AD",
          "type": "status",
          "attributes": {
            "success": true,
            "timestamp": "2020-06-11T14:04:26.290-05:00",
            "category": "menu_publish"
          }
        }, {
          "id": "00ECF20C-06D6-44AD-B478-DD4364B1A4F6",
          "type": "status",
          "attributes": {
            "success": true,
            "timestamp": "2020-06-11T14:04:26.300-05:00",
            "category": "menu_publish"
          }
        }, {
          "id": "D87EB3DD-98EF-4CA1-97DD-0CF7CEA97227",
          "type": "status",
          "attributes": {
            "success": true,
            "timestamp": "2020-06-11T14:04:26.306-05:00",
            "category": "menu_publish"
          }
        }, {
          "id": "CAB9C6EB-CC1C-47E6-A7A5-10B53E5ADD01",
          "type": "status",
          "attributes": {
            "success": true,
            "timestamp": "2020-06-11T14:04:26.493-05:00",
            "category": "menu_publish"
          }
        }, {
          "id": "1F654302-BF73-4DE4-93D3-08B8F3004C96",
          "type": "status",
          "attributes": {
            "success": true,
            "timestamp": "2020-06-11T14:04:24.523-05:00",
            "category": "menu_import"
          }
        }, {
          "id": "13283",
          "type": "order_platform",
          "attributes": {
            "id": 13283,
            "name": "Grubhub"
          },
          "relationships": {
            "partner": {
              "data": {
                "id": "5355",
                "type": "partner"
              }
            },
            "menu_mapping_menu": {
              "data": null
            },
            "menu_publish_status": {
              "data": {
                "id": "99142B08-9C42-4784-B390-D952560B9838",
                "type": "status"
              }
            }
          }
        }, {
          "id": "13284",
          "type": "order_platform",
          "attributes": {
            "id": 13284,
            "name": "UberEats"
          },
          "relationships": {
            "partner": {
              "data": {
                "id": "5355",
                "type": "partner"
              }
            },
            "menu_mapping_menu": {
              "data": null
            },
            "menu_publish_status": {
              "data": {
                "id": "A4076E35-2B43-4FB8-A834-DFBB7042D1AC",
                "type": "status"
              }
            }
          }
        }, {
          "id": "13285",
          "type": "order_platform",
          "attributes": {
            "id": 13285,
            "name": "Chownow"
          },
          "relationships": {
            "partner": {
              "data": {
                "id": "5355",
                "type": "partner"
              }
            },
            "menu_mapping_menu": {
              "data": null
            },
            "menu_publish_status": {
              "data": {
                "id": "BEB94E40-67FB-41A7-ADB9-ABD61798A137",
                "type": "status"
              }
            }
          }
        }, {
          "id": "13286",
          "type": "order_platform",
          "attributes": {
            "id": 13286,
            "name": "Caviar"
          },
          "relationships": {
            "partner": {
              "data": {
                "id": "5355",
                "type": "partner"
              }
            },
            "menu_mapping_menu": {
              "data": null
            },
            "menu_publish_status": {
              "data": {
                "id": "6B8AC2BD-F6A1-405F-9AF9-99AFF9920AD8",
                "type": "status"
              }
            }
          }
        }, {
          "id": "13287",
          "type": "order_platform",
          "attributes": {
            "id": 13287,
            "name": "Postmates"
          },
          "relationships": {
            "partner": {
              "data": {
                "id": "5355",
                "type": "partner"
              }
            },
            "menu_mapping_menu": {
              "data": null
            },
            "menu_publish_status": {
              "data": {
                "id": "AC3318EC-C5B2-42C6-9A48-1430AB818048",
                "type": "status"
              }
            }
          }
        }, {
          "id": "13303",
          "type": "order_platform",
          "attributes": {
            "id": 13303,
            "name": "Garcon"
          },
          "relationships": {
            "partner": {
              "data": {
                "id": "5355",
                "type": "partner"
              }
            },
            "menu_mapping_menu": {
              "data": null
            },
            "menu_publish_status": {
              "data": {
                "id": "4489C8F8-15BE-4238-BBAB-7C123C732A4A",
                "type": "status"
              }
            }
          }
        }, {
          "id": "16715",
          "type": "order_platform",
          "attributes": {
            "id": 16715,
            "name": "Ritual"
          },
          "relationships": {
            "partner": {
              "data": {
                "id": "5355",
                "type": "partner"
              }
            },
            "menu_mapping_menu": {
              "data": null
            },
            "menu_publish_status": {
              "data": {
                "id": "D76BE913-A014-4521-9347-9F480901C354",
                "type": "status"
              }
            }
          }
        }, {
          "id": "99142B08-9C42-4784-B390-D952560B9838",
          "type": "status",
          "attributes": {
            "success": true,
            "timestamp": "2020-06-11T14:04:26.316-05:00",
            "category": "menu_publish"
          }
        }, {
          "id": "A4076E35-2B43-4FB8-A834-DFBB7042D1AC",
          "type": "status",
          "attributes": {
            "success": true,
            "timestamp": "2020-06-11T14:04:26.323-05:00",
            "category": "menu_publish"
          }
        }, {
          "id": "BEB94E40-67FB-41A7-ADB9-ABD61798A137",
          "type": "status",
          "attributes": {
            "success": true,
            "timestamp": "2020-06-11T14:04:26.333-05:00",
            "category": "menu_publish"
          }
        }, {
          "id": "6B8AC2BD-F6A1-405F-9AF9-99AFF9920AD8",
          "type": "status",
          "attributes": {
            "success": true,
            "timestamp": "2020-06-11T14:04:26.343-05:00",
            "category": "menu_publish"
          }
        }, {
          "id": "AC3318EC-C5B2-42C6-9A48-1430AB818048",
          "type": "status",
          "attributes": {
            "success": true,
            "timestamp": "2020-06-11T14:04:26.353-05:00",
            "category": "menu_publish"
          }
        }, {
          "id": "4489C8F8-15BE-4238-BBAB-7C123C732A4A",
          "type": "status",
          "attributes": {
            "success": true,
            "timestamp": "2020-06-11T14:04:26.373-05:00",
            "category": "menu_publish"
          }
        }, {
          "id": "D76BE913-A014-4521-9347-9F480901C354",
          "type": "status",
          "attributes": {
            "success": true,
            "timestamp": "2020-06-11T14:04:26.609-05:00",
            "category": "menu_publish"
          }
        }, {
          "id": "FC09FF58-659D-424C-AE3F-ADEF2E197BBE",
          "type": "status",
          "attributes": {
            "success": true,
            "timestamp": "2020-06-11T14:04:24.563-05:00",
            "category": "menu_import"
          }
        }, {
          "id": "13302",
          "type": "order_platform",
          "attributes": {
            "id": 13302,
            "name": "UberEats"
          },
          "relationships": {
            "partner": {
              "data": {
                "id": "5375",
                "type": "partner"
              }
            },
            "menu_mapping_menu": {
              "data": null
            },
            "menu_publish_status": {
              "data": {
                "id": "E8474AFB-E1D5-40B6-8ABF-091DDBCAA824",
                "type": "status"
              }
            }
          }
        }, {
          "id": "13509",
          "type": "order_platform",
          "attributes": {
            "id": 13509,
            "name": "Grubhub"
          },
          "relationships": {
            "partner": {
              "data": {
                "id": "5375",
                "type": "partner"
              }
            },
            "menu_mapping_menu": {
              "data": null
            },
            "menu_publish_status": {
              "data": {
                "id": "E845FB7C-4126-40DE-923F-75DAD934CCA8",
                "type": "status"
              }
            }
          }
        }, {
          "id": "13510",
          "type": "order_platform",
          "attributes": {
            "id": 13510,
            "name": "Postmates"
          },
          "relationships": {
            "partner": {
              "data": {
                "id": "5375",
                "type": "partner"
              }
            },
            "menu_mapping_menu": {
              "data": null
            },
            "menu_publish_status": {
              "data": {
                "id": "052F897E-0CBE-4D80-B5C0-6041213A632C",
                "type": "status"
              }
            }
          }
        }, {
          "id": "13511",
          "type": "order_platform",
          "attributes": {
            "id": 13511,
            "name": "Chownow"
          },
          "relationships": {
            "partner": {
              "data": {
                "id": "5375",
                "type": "partner"
              }
            },
            "menu_mapping_menu": {
              "data": null
            },
            "menu_publish_status": {
              "data": {
                "id": "DD908642-4EE3-4197-8A7E-7BE395410CC3",
                "type": "status"
              }
            }
          }
        }, {
          "id": "13512",
          "type": "order_platform",
          "attributes": {
            "id": 13512,
            "name": "Caviar"
          },
          "relationships": {
            "partner": {
              "data": {
                "id": "5375",
                "type": "partner"
              }
            },
            "menu_mapping_menu": {
              "data": null
            },
            "menu_publish_status": {
              "data": {
                "id": "FF61CBB3-CC70-4EF2-B307-03EBFB46E96F",
                "type": "status"
              }
            }
          }
        }, {
          "id": "13628",
          "type": "order_platform",
          "attributes": {
            "id": 13628,
            "name": "Garcon"
          },
          "relationships": {
            "partner": {
              "data": {
                "id": "5375",
                "type": "partner"
              }
            },
            "menu_mapping_menu": {
              "data": null
            },
            "menu_publish_status": {
              "data": {
                "id": "1BB04C8F-566B-4F32-A4B3-4454B6933FA8",
                "type": "status"
              }
            }
          }
        }, {
          "id": "14106",
          "type": "order_platform",
          "attributes": {
            "id": 14106,
            "name": "Ritual"
          },
          "relationships": {
            "partner": {
              "data": {
                "id": "5375",
                "type": "partner"
              }
            },
            "menu_mapping_menu": {
              "data": null
            },
            "menu_publish_status": {
              "data": {
                "id": "58A0FC31-6691-4AEC-BA59-C7497C759438",
                "type": "status"
              }
            }
          }
        }, {
          "id": "E8474AFB-E1D5-40B6-8ABF-091DDBCAA824",
          "type": "status",
          "attributes": {
            "success": true,
            "timestamp": "2020-06-11T14:04:26.363-05:00",
            "category": "menu_publish"
          }
        }, {
          "id": "E845FB7C-4126-40DE-923F-75DAD934CCA8",
          "type": "status",
          "attributes": {
            "success": true,
            "timestamp": "2020-06-11T14:04:26.400-05:00",
            "category": "menu_publish"
          }
        }, {
          "id": "052F897E-0CBE-4D80-B5C0-6041213A632C",
          "type": "status",
          "attributes": {
            "success": true,
            "timestamp": "2020-06-11T14:04:26.413-05:00",
            "category": "menu_publish"
          }
        }, {
          "id": "DD908642-4EE3-4197-8A7E-7BE395410CC3",
          "type": "status",
          "attributes": {
            "success": true,
            "timestamp": "2020-06-11T14:04:26.423-05:00",
            "category": "menu_publish"
          }
        }, {
          "id": "FF61CBB3-CC70-4EF2-B307-03EBFB46E96F",
          "type": "status",
          "attributes": {
            "success": true,
            "timestamp": "2020-06-11T14:04:26.430-05:00",
            "category": "menu_publish"
          }
        }, {
          "id": "1BB04C8F-566B-4F32-A4B3-4454B6933FA8",
          "type": "status",
          "attributes": {
            "success": true,
            "timestamp": "2020-06-11T14:04:26.440-05:00",
            "category": "menu_publish"
          }
        }, {
          "id": "58A0FC31-6691-4AEC-BA59-C7497C759438",
          "type": "status",
          "attributes": {
            "success": true,
            "timestamp": "2020-06-11T14:04:26.446-05:00",
            "category": "menu_publish"
          }
        }, {
          "id": "B8BE65AE-F87D-49EA-AF99-7F1712F56D69",
          "type": "status",
          "attributes": {
            "success": true,
            "timestamp": "2020-06-11T14:04:24.576-05:00",
            "category": "menu_import"
          }
        }, {
          "id": "13431",
          "type": "order_platform",
          "attributes": {
            "id": 13431,
            "name": "Grubhub"
          },
          "relationships": {
            "partner": {
              "data": {
                "id": "5397",
                "type": "partner"
              }
            },
            "menu_mapping_menu": {
              "data": null
            },
            "menu_publish_status": {
              "data": {
                "id": "49FEE97A-F2E3-45B9-AC93-78DEC2568D11",
                "type": "status"
              }
            }
          }
        }, {
          "id": "13507",
          "type": "order_platform",
          "attributes": {
            "id": 13507,
            "name": "Garcon"
          },
          "relationships": {
            "partner": {
              "data": {
                "id": "5397",
                "type": "partner"
              }
            },
            "menu_mapping_menu": {
              "data": null
            },
            "menu_publish_status": {
              "data": {
                "id": "878791BC-1BED-401E-B830-C45DA7A20C1C",
                "type": "status"
              }
            }
          }
        }, {
          "id": "15449",
          "type": "order_platform",
          "attributes": {
            "id": 15449,
            "name": "Postmates"
          },
          "relationships": {
            "partner": {
              "data": {
                "id": "5397",
                "type": "partner"
              }
            },
            "menu_mapping_menu": {
              "data": null
            },
            "menu_publish_status": {
              "data": {
                "id": "C2A14873-36CC-41F9-AB19-5A9CF549E71A",
                "type": "status"
              }
            }
          }
        }, {
          "id": "49FEE97A-F2E3-45B9-AC93-78DEC2568D11",
          "type": "status",
          "attributes": {
            "success": true,
            "timestamp": "2020-06-11T14:04:26.380-05:00",
            "category": "menu_publish"
          }
        }, {
          "id": "878791BC-1BED-401E-B830-C45DA7A20C1C",
          "type": "status",
          "attributes": {
            "success": true,
            "timestamp": "2020-06-11T14:04:26.390-05:00",
            "category": "menu_publish"
          }
        }, {
          "id": "C2A14873-36CC-41F9-AB19-5A9CF549E71A",
          "type": "status",
          "attributes": {
            "success": true,
            "timestamp": "2020-06-11T14:04:26.529-05:00",
            "category": "menu_publish"
          }
        }, {
          "id": "3432CA0C-8128-4801-B636-D58B494DFCDA",
          "type": "status",
          "attributes": {
            "success": true,
            "timestamp": "2020-06-11T14:04:24.613-05:00",
            "category": "menu_import"
          }
        }, {
          "id": "14958",
          "type": "order_platform",
          "attributes": {
            "id": 14958,
            "name": "Grubhub"
          },
          "relationships": {
            "partner": {
              "data": {
                "id": "5852",
                "type": "partner"
              }
            },
            "menu_mapping_menu": {
              "data": null
            },
            "menu_publish_status": {
              "data": {
                "id": "243B0BFE-CD52-4B84-825B-1B8E9C1A1A40",
                "type": "status"
              }
            }
          }
        }, {
          "id": "15216",
          "type": "order_platform",
          "attributes": {
            "id": 15216,
            "name": "Ritual"
          },
          "relationships": {
            "partner": {
              "data": {
                "id": "5852",
                "type": "partner"
              }
            },
            "menu_mapping_menu": {
              "data": null
            },
            "menu_publish_status": {
              "data": {
                "id": "318C6D68-41B2-44DF-BD03-607952851AFC",
                "type": "status"
              }
            }
          }
        }, {
          "id": "16266",
          "type": "order_platform",
          "attributes": {
            "id": 16266,
            "name": "DoorDash"
          },
          "relationships": {
            "partner": {
              "data": {
                "id": "5852",
                "type": "partner"
              }
            },
            "menu_mapping_menu": {
              "data": null
            },
            "menu_publish_status": {
              "data": {
                "id": "DA1326F1-100E-4F26-AD31-08C18968B921",
                "type": "status"
              }
            }
          }
        }, {
          "id": "16267",
          "type": "order_platform",
          "attributes": {
            "id": 16267,
            "name": "Postmates"
          },
          "relationships": {
            "partner": {
              "data": {
                "id": "5852",
                "type": "partner"
              }
            },
            "menu_mapping_menu": {
              "data": null
            },
            "menu_publish_status": {
              "data": {
                "id": "672AEB5A-2003-4759-8AD1-B1B64DF1991A",
                "type": "status"
              }
            }
          }
        }, {
          "id": "19875",
          "type": "order_platform",
          "attributes": {
            "id": 19875,
            "name": "Caviar"
          },
          "relationships": {
            "partner": {
              "data": {
                "id": "5852",
                "type": "partner"
              }
            },
            "menu_mapping_menu": {
              "data": null
            },
            "menu_publish_status": {
              "data": {
                "id": "61AFE4CB-AF5C-493B-A710-33DF4CC10F79",
                "type": "status"
              }
            }
          }
        }, {
          "id": "19978",
          "type": "order_platform",
          "attributes": {
            "id": 19978,
            "name": "UberEats"
          },
          "relationships": {
            "partner": {
              "data": {
                "id": "5852",
                "type": "partner"
              }
            },
            "menu_mapping_menu": {
              "data": null
            },
            "menu_publish_status": {
              "data": {
                "id": "91DDA9E9-FAD2-4C2A-A5BB-336A66304B87",
                "type": "status"
              }
            }
          }
        }, {
          "id": "243B0BFE-CD52-4B84-825B-1B8E9C1A1A40",
          "type": "status",
          "attributes": {
            "success": true,
            "timestamp": "2020-06-11T14:04:26.483-05:00",
            "category": "menu_publish"
          }
        }, {
          "id": "318C6D68-41B2-44DF-BD03-607952851AFC",
          "type": "status",
          "attributes": {
            "success": true,
            "timestamp": "2020-06-11T14:04:26.519-05:00",
            "category": "menu_publish"
          }
        }, {
          "id": "DA1326F1-100E-4F26-AD31-08C18968B921",
          "type": "status",
          "attributes": {
            "success": true,
            "timestamp": "2020-06-11T14:04:26.556-05:00",
            "category": "menu_publish"
          }
        }, {
          "id": "672AEB5A-2003-4759-8AD1-B1B64DF1991A",
          "type": "status",
          "attributes": {
            "success": true,
            "timestamp": "2020-06-11T14:04:26.566-05:00",
            "category": "menu_publish"
          }
        }, {
          "id": "61AFE4CB-AF5C-493B-A710-33DF4CC10F79",
          "type": "status",
          "attributes": {
            "success": true,
            "timestamp": "2020-06-11T14:04:26.676-05:00",
            "category": "menu_publish"
          }
        }, {
          "id": "91DDA9E9-FAD2-4C2A-A5BB-336A66304B87",
          "type": "status",
          "attributes": {
            "success": true,
            "timestamp": "2020-06-11T14:04:26.686-05:00",
            "category": "menu_publish"
          }
        }],
        "links": {
          "first": "http://localhost:3000/api/v3/partners?include=menu_import_status%2Corder_platforms%2Corder_platforms.menu_publish_status&page=1",
          "prev": null,
          "self": "http://localhost:3000/api/v3/partners?include=menu_import_status%2Corder_platforms%2Corder_platforms.menu_publish_status&page=1",
          "next": "http://localhost:3000/api/v3/partners?include=menu_import_status%2Corder_platforms%2Corder_platforms.menu_publish_status&page=2",
          "last": "http://localhost:3000/api/v3/partners?include=menu_import_status%2Corder_platforms%2Corder_platforms.menu_publish_status&page=2"
        }
      };
    });
    this.get('/printers', () => {
      return {
        "data": [{
          "id": "1",
          "type": "printer",
          "attributes": {
            "id": 1,
            "device_id": "PrnC1770000000020201902261903020502",
            "offline_at": null
          },
          "relationships": {
            "status": {
              "data": {
                "id": "5BBA0DE2-ABAE-4C3D-9C1B-3D2F4B5E8798",
                "type": "status"
              }
            },
            "partner": {
              "data": {
                "id": "3425",
                "type": "partner"
              }
            }
          }
        }],
        "included": [{
          "id": "3425",
          "type": "partner",
          "attributes": {
            "id": 3425,
            "name": "Buffalo Wild Wings - Taylor - Printer Sandbox"
          },
          "relationships": {
            "order_platforms": {
              "data": [{
                "id": "8420",
                "type": "order_platform"
              }, {
                "id": "8421",
                "type": "order_platform"
              }, {
                "id": "10066",
                "type": "order_platform"
              }]
            },
            "menu_mapping_menu": {
              "data": null
            },
            "menu_import_status": {
              "data": {
                "id": "7E74CE2C-0957-4CA0-BA61-7A024D1126F1",
                "type": "status"
              }
            }
          }
        }, {
          "id": "5BBA0DE2-ABAE-4C3D-9C1B-3D2F4B5E8798",
          "type": "status",
          "attributes": {
            "success": true,
            "timestamp": "2020-06-16T11:13:05.256-05:00",
            "category": "printer"
          }
        }]
      };
    });
  }
});