define("aboyeur/components/layout/location-explorer", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
      <div class="container mx-auto">
      {{yield}}
    </div>
  */
  {
    id: "EhQlfMub",
    block: "{\"symbols\":[\"&default\"],\"statements\":[[2,\"  \"],[10,\"div\"],[14,0,\"container mx-auto\"],[12],[2,\"\\n    \"],[18,1,null],[2,\"\\n  \"],[13]],\"hasEval\":false,\"upvars\":[]}",
    meta: {
      moduleName: "aboyeur/components/layout/location-explorer.hbs"
    }
  });

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());

  _exports.default = _default;
});