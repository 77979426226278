define("aboyeur/services/swal", ["exports", "ember-sweetalert/services/swal"], function (_exports, _swal) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function () {
      return _swal.default;
    }
  });
});