define("aboyeur/components/layout/button", ["exports", "@glimmer/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <button data-test-signin class="bg-transparent font-semibold py-2 px-4 border hover:border-transparent rounded {{this.typeClasses}}" ...attributes>
    {{yield}}
  </button>
  */
  {
    id: "AOZQMsSk",
    block: "{\"symbols\":[\"&attrs\",\"&default\"],\"statements\":[[11,\"button\"],[24,\"data-test-signin\",\"\"],[16,0,[31,[\"bg-transparent font-semibold py-2 px-4 border hover:border-transparent rounded \",[32,0,[\"typeClasses\"]]]]],[17,1],[12],[2,\"\\n  \"],[18,2,null],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[]}",
    meta: {
      moduleName: "aboyeur/components/layout/button.hbs"
    }
  });

  class LayoutButtonComponent extends _component.default {
    get typeClasses() {
      let type = this.args.type || '';

      switch (type.toLowerCase()) {
        case 'danger':
          return 'hover:bg-red hover:text-white text-red-dark border-red';
          break;

        case 'warn':
          return 'hover:bg-orange hover:text-white text-orange-dark border-orange';
          break;

        case 'success':
          return 'hover:bg-green hover:text-white text-green-dark border-green';
          break;

        case 'plain':
          return 'hover:bg-black hover:text-white text-black border-black';

        case 'white':
          return 'hover:bg-white hover:text-black text-white border-white';

        default:
          return 'hover:bg-blue hover:text-white text-blue-dark border-blue';
      }
    }

  }

  _exports.default = LayoutButtonComponent;

  Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, LayoutButtonComponent);
});