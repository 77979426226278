define("aboyeur/authenticators/chowly", ["exports", "@babel/runtime/helpers/esm/initializerDefineProperty", "@babel/runtime/helpers/esm/defineProperty", "@babel/runtime/helpers/esm/applyDecoratedDescriptor", "@babel/runtime/helpers/esm/initializerWarningHelper", "fetch", "ember-simple-auth/authenticators/torii", "aboyeur/config/environment"], function (_exports, _initializerDefineProperty2, _defineProperty2, _applyDecoratedDescriptor2, _initializerWarningHelper2, _fetch, _torii, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _class, _descriptor, _temp;

  let Authenticator = (_dec = Ember.inject.service, (_class = (_temp = class Authenticator extends _torii.default {
    constructor(...args) {
      super(...args);
      (0, _defineProperty2.default)(this, "serverTokenEndpoint", _environment.default.oauth.host + '/api/v2/authentications');
      (0, _initializerDefineProperty2.default)(this, "torii", _descriptor, this);
    }

    authenticate() {
      return super.authenticate(...arguments).then(data => {
        return (0, _fetch.default)(this.serverTokenEndpoint, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({
            client_id: _environment.default.torii.providers['chowly-oauth2'].apiKey,
            code: data.authorizationCode,
            grant_type: "authorization_code",
            redirect_uri: _environment.default.torii.providers['chowly-oauth2'].redirectUri
          })
        }).then(response => {
          return response.json().then(response => {
            response.provider = 'chowly-oauth2';
            return response;
          });
        });
      });
    }

  }, _temp), (_descriptor = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "torii", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class));
  _exports.default = Authenticator;
});