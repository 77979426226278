define("aboyeur/components/partner", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    open: false,
    orderPlatforms: Ember.computed('partner.orderPlatforms', function () {
      return this.partner.orderPlatforms.filter(op => op.aasm_state !== 'archived');
    })
  });

  _exports.default = _default;
});