define("aboyeur/controllers/printers/index", ["exports", "@babel/runtime/helpers/esm/defineProperty", "@babel/runtime/helpers/esm/applyDecoratedDescriptor", "jw-paginate"], function (_exports, _defineProperty2, _applyDecoratedDescriptor2, _jwPaginate) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _class, _temp;

  let IndexController = (_dec = Ember.computed('model.meta.pagination.{last,self}', 'model.meta.total'), _dec2 = Ember._action, _dec3 = Ember._action, _dec4 = Ember._action, _dec5 = Ember._action, _dec6 = Ember._action, (_class = (_temp = class IndexController extends Ember.Controller {
    constructor(...args) {
      super(...args);
      (0, _defineProperty2.default)(this, "sortOptions", [{
        'label': 'Sort A-Z',
        'value': {
          sort_by: 'ASC'
        }
      }, {
        'label': 'Sort Z-A',
        'value': {
          sort_by: 'DESC'
        }
      }]);
      (0, _defineProperty2.default)(this, "sortOption", 'Sort A-Z');
      (0, _defineProperty2.default)(this, "sortLabels", this.sortOptions.map(a => a.label));
      (0, _defineProperty2.default)(this, "partnerStatuses", [{
        'label': 'Enabled',
        'value': {
          partner_status: 'enabled'
        }
      }, {
        'label': 'Disabled',
        'value': {
          partner_status: 'disabled'
        }
      }]);
      (0, _defineProperty2.default)(this, "partnerStatusOption", 'Enabled');
      (0, _defineProperty2.default)(this, "partnerStatusLabels", this.partnerStatuses.map(a => a.label));
      (0, _defineProperty2.default)(this, "printerStatuses", [{
        'label': 'Offline',
        'value': {
          printer_status: 'offline'
        }
      }, {
        'label': 'Online',
        'value': {
          printer_status: 'online'
        }
      }, {
        'label': 'All',
        'value': {
          printer_status: ''
        }
      }]);
      (0, _defineProperty2.default)(this, "printerStatusOption", 'All');
      (0, _defineProperty2.default)(this, "printerStatusLabels", this.printerStatuses.map(a => a.label));
    }

    selectSort(option) {
      this.set('queryParam', this.sortOptions.find(o => o.label === option)['value']);
      this.transitionToRoute({
        queryParams: this.queryParam
      });
      return this.set('sortOption', option);
    }

    selectPartnerStatus(option) {
      this.set('queryParam', this.partnerStatuses.find(o => o.label === option)['value']);
      this.transitionToRoute({
        queryParams: this.queryParam
      });
      return this.set('partnerStatusOption', option);
    }

    selectPrinterStatus(option) {
      this.set('queryParam', this.printerStatuses.find(o => o.label === option)['value']);
      this.transitionToRoute({
        queryParams: this.queryParam
      });
      return this.set('printerStatusOption', option);
    }

    searchChowly(partner_id) {
      if (partner_id.length < 3) {
        return;
      }

      return this.store.query('printer', {
        q: partner_id,
        include: 'partner,status'
      });
    }

    goToPrinter(printer_id) {
      this.transitionToRoute('printers.printer', printer_id);
    }

    get count() {
      const total = this.model.meta.total;
      if (!total) return [];
      const currentPage = this.model.meta.pagination.self;
      const pagination = (0, _jwPaginate.default)(total, currentPage, 25);
      return pagination.pages;
    }

  }, _temp), ((0, _applyDecoratedDescriptor2.default)(_class.prototype, "selectSort", [_dec, _dec2], Object.getOwnPropertyDescriptor(_class.prototype, "selectSort"), _class.prototype), (0, _applyDecoratedDescriptor2.default)(_class.prototype, "selectPartnerStatus", [_dec3], Object.getOwnPropertyDescriptor(_class.prototype, "selectPartnerStatus"), _class.prototype), (0, _applyDecoratedDescriptor2.default)(_class.prototype, "selectPrinterStatus", [_dec4], Object.getOwnPropertyDescriptor(_class.prototype, "selectPrinterStatus"), _class.prototype), (0, _applyDecoratedDescriptor2.default)(_class.prototype, "searchChowly", [_dec5], Object.getOwnPropertyDescriptor(_class.prototype, "searchChowly"), _class.prototype), (0, _applyDecoratedDescriptor2.default)(_class.prototype, "goToPrinter", [_dec6], Object.getOwnPropertyDescriptor(_class.prototype, "goToPrinter"), _class.prototype)), _class));
  _exports.default = IndexController;
});