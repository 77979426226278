define("aboyeur/routes/printers/index", ["exports", "@babel/runtime/helpers/esm/defineProperty", "aboyeur/routes/protected", "aboyeur/config/environment"], function (_exports, _defineProperty2, _protected, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class IndexRoute extends _protected.default {
    constructor(...args) {
      super(...args);
      (0, _defineProperty2.default)(this, "queryParams", {
        page: {
          refreshModel: true
        },
        partner_status: {
          refreshModel: true
        },
        printer_status: {
          refreshModel: true
        },
        sort_by: {
          refreshModel: true
        }
      });
    }

    model(params) {
      var interval = _environment.default.printerRefreshInterval;
      Ember.run.later(this, function () {
        this.model();
      }, interval);
      return this.store.query('printer', {
        page: params.page,
        sort_by: params.sort_by,
        partner_status: params.partner_status,
        printer_status: params.printer_status,
        include: 'partner,status'
      }).then(results => {
        return {
          printers: results,
          meta: results.meta
        };
      });
    }

  }

  _exports.default = IndexRoute;
});