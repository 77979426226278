define("aboyeur/templates/components/etw/module-section", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "3QYo9R/H",
    "block": "{\"symbols\":[\"moduleStyle\",\"snippet\"],\"statements\":[[10,\"section\"],[12],[2,\"\\n  \"],[10,\"h2\"],[14,0,\"etw-pt-8 etw-mb-6 etw-text-lg etw-font-bold\"],[12],[1,[34,1]],[13],[2,\"\\n\\n  \"],[10,\"ul\"],[14,0,\"etw-list-reset etw-leading-normal\"],[12],[2,\"\\n\"],[6,[37,4],[[30,[36,3],[[30,[36,3],[[35,2]],null]],null]],null,[[\"default\"],[{\"statements\":[[2,\"      \"],[10,\"li\"],[12],[10,\"code\"],[12],[1,[32,2]],[13],[13],[2,\"\\n\"]],\"parameters\":[2]}]]],[2,\"  \"],[13],[2,\"\\n\\n  \"],[10,\"div\"],[14,0,\"etw-mt-8 etw-flex etw-flex-wrap\"],[12],[2,\"\\n\"],[6,[37,4],[[30,[36,3],[[30,[36,3],[[35,5]],null]],null]],null,[[\"default\"],[{\"statements\":[[2,\"      \"],[1,[30,[36,0],null,[[\"moduleStyle\"],[[32,1]]]]],[2,\"\\n\"]],\"parameters\":[1]}]]],[2,\"  \"],[13],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"etw/module-style-example\",\"title\",\"codeSnippets\",\"-track-array\",\"each\",\"moduleStyles\"]}",
    "meta": {
      "moduleName": "aboyeur/templates/components/etw/module-section.hbs"
    }
  });

  _exports.default = _default;
});