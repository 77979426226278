define("aboyeur/models/menu-import-status", ["exports", "aboyeur/models/status"], function (_exports, _status) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class MenuImportStatus extends _status.default {}

  _exports.default = MenuImportStatus;
});