define("aboyeur/adapters/application", ["exports", "active-model-adapter", "ember-cli-uuid/mixins/adapters/uuid", "aboyeur/config/environment"], function (_exports, _activeModelAdapter, _uuid, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _activeModelAdapter.default.extend(_uuid.default, {
    session: Ember.inject.service(),

    get authorizer() {
      return this.session.data.authenticated.authenticator.replace("authenticator", "authorizer");
    },

    host: _environment.default.api.host,
    namespace: _environment.default.api.namespace,

    ajaxOptions() {
      let hash = this._super(...arguments);

      if (this.authorizer) {
        const auth = this.session.data.authenticated;
        const myHeaders = {};
        myHeaders['Authorization'] = `${auth.token_type} ${auth.access_token}`;
        const headers = Object.assign({}, hash['headers'], myHeaders);
        hash['headers'] = headers;
      }

      return hash;
    }

  });

  _exports.default = _default;
});