define("aboyeur/templates/application", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "R7IS3+Yy",
    "block": "{\"symbols\":[],\"statements\":[[6,[37,3],[[32,0,[\"session\",\"isAuthenticated\"]]],null,[[\"default\"],[{\"statements\":[[2,\"  \"],[8,\"layout/main\",[],[[\"@signOut\"],[[30,[36,2],[[32,0,[\"signOut\"]]],null]]],[[\"default\"],[{\"statements\":[[2,\"\\n  \"]],\"parameters\":[]}]]],[2,\"\\n\\n  \"],[8,\"layout/row\",[],[[],[]],[[\"default\"],[{\"statements\":[],\"parameters\":[]}]]],[2,\"\\n  \"],[8,\"layout/row\",[],[[],[]],[[\"default\"],[{\"statements\":[],\"parameters\":[]}]]],[2,\"\\n\\n  \"],[8,\"layout/location-explorer\",[],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[8,\"layout/navbar\",[],[[],[]],[[\"default\"],[{\"statements\":[],\"parameters\":[]}]]],[2,\"\\n    \"],[1,[30,[36,1],[[30,[36,0],null,null]],null]],[2,\"\\n  \"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"parameters\":[]}]]],[6,[37,4],[[32,0,[\"session\",\"isAuthenticated\"]]],null,[[\"default\"],[{\"statements\":[[2,\"  \"],[1,[30,[36,1],[[30,[36,0],null,null]],null]],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"-outlet\",\"component\",\"fn\",\"if\",\"unless\"]}",
    "meta": {
      "moduleName": "aboyeur/templates/application.hbs"
    }
  });

  _exports.default = _default;
});