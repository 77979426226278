define("aboyeur/templates/components/label", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "U1z3T3ow",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[10,\"label\"],[14,0,\"block uppercase tracking-wide text-black text-xs font-bold mb-2\"],[12],[2,\"\\n  \"],[18,1,null],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[]}",
    "meta": {
      "moduleName": "aboyeur/templates/components/label.hbs"
    }
  });

  _exports.default = _default;
});