define("aboyeur/components/layout/navbar", ["exports", "@babel/runtime/helpers/esm/initializerDefineProperty", "@babel/runtime/helpers/esm/defineProperty", "@babel/runtime/helpers/esm/applyDecoratedDescriptor", "@babel/runtime/helpers/esm/initializerWarningHelper", "@glimmer/component"], function (_exports, _initializerDefineProperty2, _defineProperty2, _applyDecoratedDescriptor2, _initializerWarningHelper2, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _class, _descriptor, _temp;

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <div class="flex flex-no-wrap mx-8 justify-center pb-5">
    {{#each-in this.navList as |route label|}}
      <LinkTo
        @tagName="nav"
        @route="{{route}}"
        class="no-underline text-center flex-grow rounded-l font-semibold py-2 px-4 mr-1 border border-black rounded {{nav-class route this.routeName}}" ...attributes>
        {{label}}
      </LinkTo>
    {{/each-in}}
  </div>
  
  */
  {
    id: "M38e37Ar",
    block: "{\"symbols\":[\"label\",\"route\",\"&attrs\"],\"statements\":[[10,\"div\"],[14,0,\"flex flex-no-wrap mx-8 justify-center pb-5\"],[12],[2,\"\\n\"],[6,[37,2],[[30,[36,1],[[32,0,[\"navList\"]]],null]],null,[[\"default\"],[{\"statements\":[[2,\"    \"],[8,\"link-to\",[[16,0,[31,[\"no-underline text-center flex-grow rounded-l font-semibold py-2 px-4 mr-1 border border-black rounded \",[30,[36,0],[[32,2],[32,0,[\"routeName\"]]],null]]]],[17,3]],[[\"@tagName\",\"@route\"],[\"nav\",[31,[[32,2]]]]],[[\"default\"],[{\"statements\":[[2,\"\\n      \"],[1,[32,1]],[2,\"\\n    \"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"parameters\":[1,2]}]]],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"nav-class\",\"-each-in\",\"each\"]}",
    meta: {
      moduleName: "aboyeur/components/layout/navbar.hbs"
    }
  });

  // import { computed } from '@ember/object'
  let LayoutNavbarComponent = (_dec = Ember.inject.service, (_class = (_temp = class LayoutNavbarComponent extends _component.default {
    constructor(...args) {
      super(...args);
      (0, _initializerDefineProperty2.default)(this, "router", _descriptor, this);
    }

    get routeName() {
      return this.router.currentRouteName;
    }

    get navList() {
      return {
        partners: 'Locations',
        printers: 'Printers'
      };
    }

  }, _temp), (_descriptor = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "router", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class));
  _exports.default = LayoutNavbarComponent;

  Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, LayoutNavbarComponent);
});