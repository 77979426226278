define("aboyeur/serializers/application", ["exports", "@ember-data/serializer/json-api"], function (_exports, _jsonApi) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class ApplicationSerializer extends _jsonApi.default {
    keyForRelationship(key, relationship, method) {
      return Ember.String.underscore(key);
    }

    keyForAttribute(key) {
      return Ember.String.underscore(key);
    }

    normalizeQueryResponse(store, clazz, payload) {
      let result = super.normalizeQueryResponse(...arguments);
      result.meta = result.meta || {};
      result.meta.pagination = this.createPageMeta(payload.links);
      return result;
    }

    createPageMeta(data) {
      if (!data) return {};
      let meta = {};
      Object.keys(data).forEach(type => {
        const link = data[type];
        meta[type] = null;
        if (!link) return;
        let page_param = new URL(link).searchParams.get('page');

        if (page_param) {
          meta[type] = parseInt(page_param);
        } else {
          // Page 1 doesn't have a page param so set as 1 here.
          meta[type] = 1;
        }
      });
      return meta;
    }

  }

  _exports.default = ApplicationSerializer;
});