define("aboyeur/models/user", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend({
    email: _emberData.default.attr('string'),
    type: _emberData.default.attr('string'),
    partner_id: _emberData.default.attr('number'),
    partners: _emberData.default.hasMany('Partner', {
      "async": false
    }),
    isBasic: Ember.computed('type', function () {
      return this.get('type') == null;
    }),
    permissions: Ember.computed('type', function () {
      const adminPerms = {};
      return this.get('type') == null ? userPerms : adminPerms;
    })
  });

  _exports.default = _default;
});