define("aboyeur/components/layout/nav-menu", ["exports", "@babel/runtime/helpers/esm/initializerDefineProperty", "@babel/runtime/helpers/esm/defineProperty", "@babel/runtime/helpers/esm/applyDecoratedDescriptor", "@babel/runtime/helpers/esm/initializerWarningHelper", "@glimmer/component", "aboyeur/config/environment"], function (_exports, _initializerDefineProperty2, _defineProperty2, _applyDecoratedDescriptor2, _initializerWarningHelper2, _component, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _temp;

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    
  <div class="relative">
    <button 
      {{on "click" this.toggleNav}}
      {{on "blur" this.closeNav}}
      class="focus:outline-none relative font-semibold py-2 px-4 inline-flex items-center"
    >
      <span class="mr-1 text-black">navigation</span>
      <svg class="fill-current h-4 w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z"/> </svg>
    </button>
  
    <div class="z-30 w-48 rounded py-2 mt-2 border absolute bg-white border-black shadow-md float-right {{if this.isClosed "hidden"}}">
      {{#each this.addresses as |address|}}
        <button class="outline-none min-w-full text-left block px-4 py-2 text-gray-800 hover:bg-black hover:text-white address-button" {{on "click" (fn this.openApp address.url)}}>{{address.name}}</button>
      {{/each}}
    </div>
  </div>
  */
  {
    id: "U/ohOeyX",
    block: "{\"symbols\":[\"address\"],\"statements\":[[2,\"\\n\"],[10,\"div\"],[14,0,\"relative\"],[12],[2,\"\\n  \"],[11,\"button\"],[24,0,\"focus:outline-none relative font-semibold py-2 px-4 inline-flex items-center\"],[4,[38,1],[\"click\",[32,0,[\"toggleNav\"]]],null],[4,[38,1],[\"blur\",[32,0,[\"closeNav\"]]],null],[12],[2,\"\\n    \"],[10,\"span\"],[14,0,\"mr-1 text-black\"],[12],[2,\"navigation\"],[13],[2,\"\\n    \"],[10,\"svg\"],[14,0,\"fill-current h-4 w-4\"],[14,\"xmlns\",\"http://www.w3.org/2000/svg\",\"http://www.w3.org/2000/xmlns/\"],[14,\"viewBox\",\"0 0 20 20\"],[12],[10,\"path\"],[14,\"d\",\"M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z\"],[12],[13],[2,\" \"],[13],[2,\"\\n  \"],[13],[2,\"\\n\\n  \"],[10,\"div\"],[15,0,[31,[\"z-30 w-48 rounded py-2 mt-2 border absolute bg-white border-black shadow-md float-right \",[30,[36,2],[[32,0,[\"isClosed\"]],\"hidden\"],null]]]],[12],[2,\"\\n\"],[6,[37,4],[[30,[36,3],[[30,[36,3],[[32,0,[\"addresses\"]]],null]],null]],null,[[\"default\"],[{\"statements\":[[2,\"      \"],[11,\"button\"],[24,0,\"outline-none min-w-full text-left block px-4 py-2 text-gray-800 hover:bg-black hover:text-white address-button\"],[4,[38,1],[\"click\",[30,[36,0],[[32,0,[\"openApp\"]],[32,1,[\"url\"]]],null]],null],[12],[1,[32,1,[\"name\"]]],[13],[2,\"\\n\"]],\"parameters\":[1]}]]],[2,\"  \"],[13],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[\"fn\",\"on\",\"if\",\"-track-array\",\"each\"]}",
    meta: {
      moduleName: "aboyeur/components/layout/nav-menu.hbs"
    }
  });

  let NavDropdownComponent = (_dec = Ember._tracked, _dec2 = Ember._tracked, _dec3 = Ember._tracked, _dec4 = Ember._tracked, _dec5 = Ember._action, _dec6 = Ember._action, _dec7 = Ember._action, (_class = (_temp = class NavDropdownComponent extends _component.default {
    constructor(...args) {
      super(...args);
      (0, _initializerDefineProperty2.default)(this, "applicationName", _descriptor, this);
      (0, _initializerDefineProperty2.default)(this, "isClosed", _descriptor2, this);
      (0, _initializerDefineProperty2.default)(this, "configAddress", _descriptor3, this);
      (0, _initializerDefineProperty2.default)(this, "addresses", _descriptor4, this);
    }

    toggleNav() {
      this.isClosed = !this.isClosed;
    }

    closeNav(e) {
      if (!e.relatedTarget || !e.relatedTarget.classList.contains('address-button')) {
        this.isClosed = true;
      }
    }

    openApp(address) {
      var win = window.open(address, '_blank');
      win.focus();
      this.isClosed = true;
    }

  }, _temp), (_descriptor = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "applicationName", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "isClosed", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return true;
    }
  }), _descriptor3 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "configAddress", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return _environment.default.navBarUrls;
    }
  }), _descriptor4 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "addresses", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return Object.entries(this.configAddress).map(([name, url]) => ({
        name,
        url
      }));
    }
  }), (0, _applyDecoratedDescriptor2.default)(_class.prototype, "toggleNav", [_dec5], Object.getOwnPropertyDescriptor(_class.prototype, "toggleNav"), _class.prototype), (0, _applyDecoratedDescriptor2.default)(_class.prototype, "closeNav", [_dec6], Object.getOwnPropertyDescriptor(_class.prototype, "closeNav"), _class.prototype), (0, _applyDecoratedDescriptor2.default)(_class.prototype, "openApp", [_dec7], Object.getOwnPropertyDescriptor(_class.prototype, "openApp"), _class.prototype)), _class));
  _exports.default = NavDropdownComponent;

  Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, NavDropdownComponent);
});