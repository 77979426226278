define("aboyeur/templates/partners", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "zXP0AKKj",
    "block": "{\"symbols\":[],\"statements\":[[8,\"layout/row\",[],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n\"],[8,\"search-bar\",[],[[\"@partners\",\"@searchChowly\",\"@searchAction\"],[[32,0,[\"model\"]],[30,[36,0],[[32,0,[\"searchChowly\"]]],null],[30,[36,0],[[32,0,[\"goToPartner\"]]],null]]],null],[2,\"\\n\\n\"],[1,[30,[36,2],[[30,[36,1],null,null]],null]]],\"hasEval\":false,\"upvars\":[\"fn\",\"-outlet\",\"component\"]}",
    "meta": {
      "moduleName": "aboyeur/templates/partners.hbs"
    }
  });

  _exports.default = _default;
});