define("aboyeur/routes/partners/index", ["exports", "@babel/runtime/helpers/esm/defineProperty", "aboyeur/routes/protected"], function (_exports, _defineProperty2, _protected) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class IndexRoute extends _protected.default {
    constructor(...args) {
      super(...args);
      (0, _defineProperty2.default)(this, "queryParams", {
        page: {
          refreshModel: true
        },
        menu_import: {
          refreshModel: true
        },
        menu_publish: {
          refreshModel: true
        },
        toos_online_status: {
          refreshModel: true
        },
        sort_by: {
          refreshModel: true
        }
      });
    }

    model(params) {
      return this.store.query('partner', {
        page: params.page,
        menu_import: params.menu_import,
        menu_publish: params.menu_publish,
        toos_online_status: params.toos_online_status,
        sort_by: params.sort_by,
        include: 'menu_import_status,order_platforms,order_platforms.online_status,order_platforms.menu_publish_status'
      }).then(results => {
        return {
          partners: results,
          meta: results.meta
        };
      });
    }

  }

  _exports.default = IndexRoute;
});