define("aboyeur/serializers/user", ["exports", "active-model-adapter/active-model-serializer"], function (_exports, _activeModelSerializer) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _activeModelSerializer.default.extend({});

  _exports.default = _default;
});