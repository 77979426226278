define("aboyeur/components/layout/main", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <Layout::Nav @signOut={{action @signOut}}></Layout::Nav>
  {{yield}}
  */
  {
    id: "tTGz+TFW",
    block: "{\"symbols\":[\"@signOut\",\"&default\"],\"statements\":[[8,\"layout/nav\",[],[[\"@signOut\"],[[30,[36,0],[[32,0],[32,1]],null]]],[[\"default\"],[{\"statements\":[],\"parameters\":[]}]]],[2,\"\\n\"],[18,2,null]],\"hasEval\":false,\"upvars\":[\"action\"]}",
    meta: {
      moduleName: "aboyeur/components/layout/main.hbs"
    }
  });

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());

  _exports.default = _default;
});