define("aboyeur/controllers/application-tailwind", ["exports", "aboyeur/tailwind/config/colors", "aboyeur/tailwind/config/screens", "aboyeur/tailwind/config/fonts", "aboyeur/tailwind/config/text-sizes", "aboyeur/tailwind/config/font-weights", "aboyeur/tailwind/config/line-height", "aboyeur/tailwind/config/letter-spacing", "aboyeur/tailwind/config/border-widths", "aboyeur/tailwind/config/border-radius", "aboyeur/tailwind/config/width", "aboyeur/tailwind/config/height", "aboyeur/tailwind/config/min-width", "aboyeur/tailwind/config/min-height", "aboyeur/tailwind/config/max-width", "aboyeur/tailwind/config/max-height", "aboyeur/tailwind/config/padding", "aboyeur/tailwind/config/margin", "aboyeur/tailwind/config/negative-margin", "aboyeur/tailwind/config/shadows", "aboyeur/tailwind/config/z-index", "aboyeur/tailwind/config/opacity", "aboyeur/tailwind/config/svg-fill", "aboyeur/tailwind/config/svg-stroke"], function (_exports, _colors, _screens, _fonts, _textSizes, _fontWeights, _lineHeight, _letterSpacing, _borderWidths, _borderRadius, _width, _height, _minWidth, _minHeight, _maxWidth, _maxHeight, _padding, _margin, _negativeMargin, _shadows, _zIndex, _opacity, _svgFill, _svgStroke) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const modules = {
    colors: _colors.default,
    screens: _screens.default,
    fonts: _fonts.default,
    textSizes: _textSizes.default,
    fontWeights: _fontWeights.default,
    leading: _lineHeight.default,
    tracking: _letterSpacing.default,
    borderWidths: _borderWidths.default,
    borderRadius: _borderRadius.default,
    width: _width.default,
    height: _height.default,
    minWidth: _minWidth.default,
    minHeight: _minHeight.default,
    maxWidth: _maxWidth.default,
    maxHeight: _maxHeight.default,
    padding: _padding.default,
    margin: _margin.default,
    negativeMargin: _negativeMargin.default,
    shadows: _shadows.default,
    zIndex: _zIndex.default,
    opacity: _opacity.default,
    svgFill: _svgFill.default,
    svgStroke: _svgStroke.default
  };

  var _default = Ember.Controller.extend({
    /*
      A module style is an object that looks like
       {
        module: 'border-radius',
        name: 'lg',
        value: '.5rem'
      }
    */
    moduleStyles: Ember.computed(function () {
      return Object.keys(modules).reduce((allModules, moduleName) => {
        let hash = modules[moduleName];
        allModules[moduleName] = Object.keys(hash).map(key => {
          return {
            module: Ember.String.dasherize(moduleName),
            name: key,
            value: hash[key]
          };
        });
        return allModules;
      }, {});
    })
  });

  _exports.default = _default;
});